<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>材料价格发布</h3>
      </div>
      <div class="query" v-if="materialId === ''">
        <el-input
          type="text"
          v-model.trim="input"
          style="width: 300px; margin-right: 10px"
          placeholder="请输入材料编码"
        ></el-input>
        <el-button type="primary" @click="query">查询</el-button>
        <el-button @click="refreshs">刷新</el-button>
      </div>
      <div class="query" v-else>
        <el-input
          type="text"
          v-model.trim="input1"
          style="width: 300px; margin-right: 10px"
          placeholder="请输入克重"
        ></el-input>
        <el-button type="primary" @click="query">查询</el-button>
        <el-button @click="refreshs">刷新</el-button>
        <el-button type="primary" @click="back">返回</el-button>
      </div>
      <div class="tab" v-show="materialId === ''">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            prop="code"
            label="材料编码"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="材料名称"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="valuationtype"
            label="计价方式"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            width="200"
            fixed="right"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button type="primary" @click="cpdWeight(scope.row)"
                >对应克重</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="tab" v-show="materialId !== ''">
        <el-table :data="tableData1" border style="width: 100%">
          <el-table-column
            prop="name"
            label="克重名称"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="purchaseprice"
            label="采购价格"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            label="上浮系数(商城)"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.weightingcoeffcient }}%</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200"
            align="center"
            fixed="right"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button type="warning" @click="editPrice(scope.row)"
                >修改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="pageNumber1"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize1"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total1"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 编辑材料价格 -->
    <el-dialog
      title="编辑材料价格"
      :visible.sync="dialogVisible"
      @close="close"
      width="30%"
    >
      <div class="ipt">
        <span>克重名称</span>
        <el-input v-model="formLabelAlign.name" disabled></el-input>
      </div>
      <div class="ipt">
        <span>采购价格</span>
        <el-input v-model="formLabelAlign.price"></el-input>
      </div>
      <div class="ipt">
        <p>上浮系数</p>
        <el-input-number
          v-model="formLabelAlign.type"
          controls-position="right"
          :min="1"
        ></el-input-number>
        <span>%</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="sure"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/adminERP/erp";
export default {
  name: "orderReport",
  data() {
    return {
      loading: false,
      weightId: "", // 克重id
      total: 0,
      pageNumber: 1,
      pageSize: 10, // 材料
      total1: 0,
      pageNumber1: 1,
      pageSize1: 10, // 克重
      dialogVisible: false,
      input: "",
      input1: "",
      tableData: [],
      tableData1: [],
      materialId: "",
      formLabelAlign: {
        name: "",
        price: "",
        type: "",
      },
    };
  },
  created() {
    this.inits();
  },
  methods: {
    // 返回
    back() {
      this.materialId = "";
      this.inits();
    },
    // 确定修改
    sure() {
      this.loading = true;
      let data = {
        weightingcoeffcient: this.formLabelAlign.type,
        purchaseprice: this.formLabelAlign.price,
      };
      api
        .editWeights(this.weightId, data)
        .then((res) => {
          if (res.success) {
            this.$message.success("修改成功");
            this.getWeight();
            this.loading = false;
            this.dialogVisible = false;
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 弹窗关闭
    close() {
      this.formLabelAlign = {
        name: "",
        price: "",
        type: "",
      };
      this.dialogVisible = false;
    },
    // 修改价格
    editPrice(item) {
      this.dialogVisible = true;
      this.weightId = item.id;
      let { name, purchaseprice, weightingcoeffcient } = item;
      this.formLabelAlign = {
        name: name,
        price: purchaseprice,
        type: Number(weightingcoeffcient),
      };
    },
    // 对应克重
    cpdWeight(item) {
      this.materialId = item.id;
      this.getWeight();
    },
    // 查询
    query() {
      if (this.materialId === "") {
        if (this.input === "") {
          this.$message.warning("请输入查询内容");
          return;
        }
        this.inits();
      } else {
        if (this.input1 === "") {
          this.$message.warning("请输入查询内容");
          return;
        }
        this.getWeight();
      }
    },
    // 刷新
    refreshs() {
      if (this.materialId) {
        this.input1 = "";
        this.getWeight();
      } else {
        this.input = "";
        this.inits();
      }
    },
    // 获取材料数据
    inits() {
      api
        .getLists(this.pageNumber, this.pageSize, this.input)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 获取克重数据
    getWeight() {
      api
        .getWeights(
          this.pageNumber1,
          this.pageSize1,
          this.materialId,
          this.input1
        )
        .then((res) => {
          if (res.success) {
            this.tableData1 = res.data.weightPageInfo.list;
            this.total1 = res.data.weightPageInfo.total;
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 材料分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.inits();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.inits();
    },
    // 克重分页
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.getWeight();
    },
    handleCurrentChange1(val) {
      this.pageNumber1 = val;
      this.getWeight();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .query {
    margin: 10px 0;
    display: flex;
  }
}
.page {
  margin: 20px 0 10px 0;
}
.ipt {
  margin-bottom: 10px;
  ::v-deep .el-input-number {
    width: 96%;
  }
}
</style>
