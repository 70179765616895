<template>
  <div class="info">
    <div class="card-top">
      <span></span>
      <h3>会员级别管理</h3>
    </div>
    <!-- 查询条件 -->
    <div class="query">
      <!-- <el-input
        class="input"
        v-model="querys"
        placeholder="请输入客户名称"
      ></el-input>
      <el-button class="btn" type="primary" @click="getList">查询</el-button> -->
      <el-button type="primary" @click="refreshs">刷新</el-button>
    </div>
    <!--表格-->
    <div class="card-form table">
      <el-table :data="memberList" border style="width: 100%">
        <el-table-column
          header-align="center"
          align="center"
          label="客户名称"
          prop="customerName"
        ></el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="级别变更前"
          prop="customerOldLevelName"
        ></el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="级别变更后"
          prop="customerNewLevelName"
        ></el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="申请客服"
          prop="customerServiceName"
        ></el-table-column>
        <el-table-column header-align="center" align="center" label="账期时间">
          <template slot-scope="scope">
            <!-- <span v-if="scope.row.customerOldLevel == '1025'"
              >{{ scope.row.extendColumn1 }}天</span
            >
            <span v-else>----</span> -->
            <span v-if="scope.row.extendColumn1"
              >{{ scope.row.extendColumn1 }}天</span
            >
            <span v-else>----</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="记录来由"
          prop="extendColumn2"
        ></el-table-column>
        <el-table-column header-align="center" label="申请状态" align="center">
          <template slot-scope="scope">
            {{
              scope.row.applicationStatus == "0"
                ? "待审核"
                : scope.row.applicationStatus == "1"
                ? "审核通过"
                : "审核拒绝"
            }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="备注"
          prop="msgRemark"
        ></el-table-column>
        <el-table-column
          header-align="center"
          label="操作"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-button
              @click="details(scope.row)"
              :disabled="scope.row.applicationStatus !== '0'"
              type="primary"
            >
              <span>{{
                scope.row.applicationStatus !== "0" ? "已审核" : "审核"
              }}</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页-->
    <div class="pages">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 10, 20, 30]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="审核订单"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
    >
      <div class="bottom">
        <el-radio-group v-model="radio">
          <el-radio label="1">审核通过</el-radio>
          <el-radio label="2">审核驳回</el-radio>
        </el-radio-group>
      </div>
      <div class="topIpt" v-if="radio != '1'">
        <span>审核意见：</span>
        <el-input
          type="textarea"
          autosize
          placeholder="请输入审核内容"
          v-model="textarea"
          class="ipt"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/webAdmin/webAdmin";
export default {
  name: "complaintManage",
  data() {
    return {
      textarea: "",
      radio: "1",
      dialogVisible: false,
      memberList: [],
      orderId: "",
      querys: "", // 订单号查询
      checked: 0,
      total: 0,
      currentPage: 1,
      pageSize: 5,
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    cancel() {
      this.orderId = "";
      this.textarea = "";
      this.dialogVisible = false;
    },
    sure() {
      if (this.radio == "2" && this.textarea == "") {
        this.textarea = "审核拒绝";
      }
      let data = {
        msgRemark: this.radio == "1" ? "审核通过" : this.textarea,
        applicationStatus: this.radio,
      };
      api.changeMembers(this.orderId, data).then((res) => {
        console.log("审核状态", res);
        if (res.code == 200 && res.success) {
          this.$message.success("审核成功");
          this.getList();
          this.dialogVisible = false;
        } else {
          this.$message.error("审核失败");
        }
      });
    },
    // 审核
    details(item) {
      console.log("列表当前行数据", item);
      this.orderId = item.id;
      this.dialogVisible = true;
    },
    // 刷新
    refreshs() {
      this.querys = "";
      this.pageSize = 5;
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      api
        .membershipgradechangeapplicat(this.pageSize, this.currentPage)
        .then((res) => {
          console.log("获取会员升级列表", res);
          if (res.success) {
            this.memberList = res.data.list;
            this.total = res.data.total;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.bom {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  .bom-left {
    display: flex;
    .titles {
      width: 80px;
      text-align: justify;
      text-align-last: justify;
      font-weight: bold;
      color: black;
    }
  }
}
.cont {
  .title {
    width: 80px;
    text-align: justify;
    text-align-last: justify;
    font-weight: bold;
    color: black;
  }
}
.card-top {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #e6e6e6;
  padding: 0 30px;
  line-height: 50px;
  span {
    display: inline-block;
    width: 5px;
    height: 17px;
    background: #058af2;
    margin-right: 20px;
  }
  h3 {
    color: #058af2;
  }
}
/* 查询条件 */
.query {
  margin: 10px 0;
  display: flex;
  align-items: center;
  .input {
    width: 30%;
    margin: 0 10px;
  }
  .btn {
    margin-left: 10px;
  }
}
/*表格*/
.table {
  margin-top: 10px;
  margin-right: 0;
  border-top: 1px dashed #e6e6e6;
}

/*分页*/
.pages {
  display: flex;
  margin: 20px 20px;
  justify-content: space-between;
}

.topIpt {
  display: flex;
  align-items: center;
  .ipt {
    width: 80%;
  }
}
</style>
