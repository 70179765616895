<template>
    <div>
        <div class="card">
            <div class="card-top">
                <span></span>
                <h3>印刷报价配置</h3>
            </div>

            <el-form :model="dataForm" :rules="rules" ref="dataForm" label-width=""
                     class="demo-ruleForm">
                <div class="tab">
                    <table>
                        <tr>
                            <th>色数</th>
                            <th>纸张克重(单位：克)</th>
                            <th>开料尺寸(单位：mm)</th>
                            <th>板材费用(单位：元/时)</th>
                            <th>印刷工价(单位：元/干印/色)</th>
                            <th>起印数量(单位：干印/色)</th>
                        </tr>
                        <tr>
                            <th rowspan="2">四色</th>
                            <td>
                                <el-form-item label="" prop="weight4">
                                    <el-input :placeholder="data.weight4" v-model="dataForm.weight4"></el-input>
                                </el-form-item>
                            </td>
                            <td style="display: flex">
                                <el-form-item label="" prop="minSize4">
                                    <el-input :placeholder="data.minSize4" v-model="dataForm.minSize4"></el-input>
                                </el-form-item>
                                <div class="text"></div>
                                <el-form-item label="" prop="maxSize4">
                                    <el-input :placeholder="data.maxSize4" v-model="dataForm.maxSize4"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="plateFee41">
                                    <el-input :placeholder="data.plateFee41" v-model="dataForm.plateFee41"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice41">
                                    <el-input :placeholder="data.printPrice41"
                                              v-model="dataForm.printPrice41"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum41">
                                    <el-input :placeholder="data.startNum41" v-model="dataForm.startNum41"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">其他</td>
                            <td>
                                <el-form-item label="" prop="plateFee42">
                                    <el-input :placeholder="data.plateFee42" v-model="dataForm.plateFee42"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice42">
                                    <el-input :placeholder="data.printPrice42"
                                              v-model="dataForm.printPrice42"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum42">
                                    <el-input :placeholder="data.startNum42" v-model="dataForm.startNum42"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2">双色</th>
                            <td>
                                <el-form-item label="" prop="weight2">
                                    <el-input :placeholder="data.weight2" v-model="dataForm.weight2"></el-input>
                                </el-form-item>
                            </td>
                            <td style="display: flex">
                                <el-form-item label="" prop="minSize2">
                                    <el-input :placeholder="data.minSize2" v-model="dataForm.minSize2"></el-input>
                                </el-form-item>
                                <div class="text"></div>
                                <el-form-item label="" prop="maxSize2">
                                    <el-input :placeholder="data.maxSize2" v-model="dataForm.maxSize2"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="plateFee21">
                                    <el-input :placeholder="data.plateFee21" v-model="dataForm.plateFee21"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice21">
                                    <el-input :placeholder="data.printPrice21"
                                              v-model="dataForm.printPrice21"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum21">
                                    <el-input :placeholder="data.startNum21" v-model="dataForm.startNum21"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">其他</td>
                            <td>
                                <el-form-item label="" prop="plateFee22">
                                    <el-input :placeholder="data.plateFee22" v-model="dataForm.plateFee22"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice22">
                                    <el-input :placeholder="data.printPrice22"
                                              v-model="dataForm.printPrice22"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum22">
                                    <el-input :placeholder="data.startNum22" v-model="dataForm.startNum22"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2">单色</th>
                            <td>
                                <el-form-item label="" prop="weight1">
                                    <el-input :placeholder="data.weight1" v-model="dataForm.weight1"></el-input>
                                </el-form-item>
                            </td>
                            <td style="display: flex">
                                <el-form-item label="" prop="minSize1">
                                    <el-input :placeholder="data.minSize1" v-model="dataForm.minSize1"></el-input>
                                </el-form-item>
                                <div class="text"></div>
                                <el-form-item label="" prop="maxSize1">
                                    <el-input :placeholder="data.maxSize1" v-model="dataForm.maxSize1"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="plateFee11">
                                    <el-input :placeholder="data.plateFee11" v-model="dataForm.plateFee11"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice11">
                                    <el-input :placeholder="data.printPrice11"
                                              v-model="dataForm.printPrice11"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum11">
                                    <el-input :placeholder="data.startNum11" v-model="dataForm.startNum11"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">其他</td>
                            <td>
                                <el-form-item label="" prop="plateFee12">
                                    <el-input :placeholder="data.plateFee12" v-model="dataForm.plateFee12"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice12">
                                    <el-input :placeholder="data.printPrice12"
                                              v-model="dataForm.printPrice12"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum12">
                                    <el-input :placeholder="data.startNum12" v-model="dataForm.startNum12"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2">+专色</th>
                            <td>
                                <el-form-item label="" prop="weight3">
                                    <el-input :placeholder="data.weight3" v-model="dataForm.weight3"></el-input>
                                </el-form-item>
                            </td>
                            <td style="display: flex">
                                <el-form-item label="" prop="minSize3">
                                    <el-input :placeholder="data.minSize3" v-model="dataForm.minSize3"></el-input>
                                </el-form-item>
                                <div class="text"></div>
                                <el-form-item label="" prop="maxSize3">
                                    <el-input :placeholder="data.maxSize3" v-model="dataForm.maxSize3"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="plateFee31">
                                    <el-input :placeholder="data.plateFee31" v-model="dataForm.plateFee31"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice31">
                                    <el-input :placeholder="data.printPrice31"
                                              v-model="dataForm.printPrice31"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum31">
                                    <el-input :placeholder="data.startNum31" v-model="dataForm.startNum31"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">其他</td>
                            <td>
                                <el-form-item label="" prop="plateFee32">
                                    <el-input :placeholder="data.plateFee32" v-model="dataForm.plateFee32"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice32">
                                    <el-input :placeholder="data.printPrice32"
                                              v-model="dataForm.printPrice32"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum32">
                                    <el-input :placeholder="data.startNum32" v-model="dataForm.startNum32"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2">+过油</th>
                            <td>
                                <el-form-item label="" prop="weight6">
                                    <el-input :placeholder="data.weight6" v-model="dataForm.weight6"></el-input>
                                </el-form-item>
                            </td>
                            <td style="display: flex">
                                <el-form-item label="" prop="minSize6">
                                    <el-input :placeholder="data.minSize6" v-model="dataForm.minSize6"></el-input>
                                </el-form-item>
                                <div class="text"></div>
                                <el-form-item label="" prop="maxSize6">
                                    <el-input :placeholder="data.maxSize6" v-model="dataForm.maxSize6"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="plateFee61">
                                    <el-input :placeholder="data.plateFee61" v-model="dataForm.plateFee61"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice61">
                                    <el-input :placeholder="data.printPrice61"
                                              v-model="dataForm.printPrice61"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum61">
                                    <el-input :placeholder="data.startNum61" v-model="dataForm.startNum61"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">其他</td>
                            <td>
                                <el-form-item label="" prop="plateFee62">
                                    <el-input :placeholder="data.plateFee62" v-model="dataForm.plateFee62"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice62">
                                    <el-input :placeholder="data.printPrice62"
                                              v-model="dataForm.printPrice62"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum62">
                                    <el-input :placeholder="data.startNum62" v-model="dataForm.startNum62"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2">满专色</th>
                            <td>
                                <el-form-item label="" prop="weight5">
                                    <el-input :placeholder="data.weight5" v-model="dataForm.weight5"></el-input>
                                </el-form-item>
                            </td>
                            <td style="display: flex">
                                <el-form-item label="" prop="minSize5">
                                    <el-input :placeholder="data.minSize5" v-model="dataForm.minSize5"></el-input>
                                </el-form-item>
                                <div class="text"></div>
                                <el-form-item label="" prop="maxSize5">
                                    <el-input :placeholder="data.maxSize5" v-model="dataForm.maxSize5"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="plateFee51">
                                    <el-input :placeholder="data.plateFee51" v-model="dataForm.plateFee51"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice51">
                                    <el-input :placeholder="data.printPrice51"
                                              v-model="dataForm.printPrice51"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum51">
                                    <el-input :placeholder="data.startNum51" v-model="dataForm.startNum51"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">其他</td>
                            <td>
                                <el-form-item label="" prop="plateFee52">
                                    <el-input :placeholder="data.plateFee52" v-model="dataForm.plateFee52"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="printPrice52">
                                    <el-input :placeholder="data.printPrice52"
                                              v-model="dataForm.printPrice52"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item label="" prop="startNum52">
                                    <el-input :placeholder="data.startNum52" v-model="dataForm.startNum52"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                    </table>

                    <el-form-item>
                        <el-button @click="submit('dataForm')" type="primary" class="btn">保存</el-button>
                    </el-form-item>

                </div>

            </el-form>

        </div>

    </div>
</template>

<script>
    import api from "../../api/adminERP/erp";
    import qs from "qs";

    export default {
        name: "offerConfigure",
        data() {
            return {
                // 提示值
                data: {
                    weight4: 250,
                    minSize4: 650,
                    maxSize4: 920,
                    plateFee41: 70,
                    printPrice41: 25,
                    startNum41: 1,
                    plateFee42: 40,
                    printPrice42: 13,
                    startNum42: 1,

                    weight2: 251,
                    minSize2: 650,
                    maxSize2: 920,
                    plateFee21: 70,
                    printPrice21: 25,
                    startNum21: 1,
                    plateFee22: 40,
                    printPrice22: 13,
                    startNum22: 1,

                    weight1: 252,
                    minSize1: 650,
                    maxSize1: 920,
                    plateFee11: 70,
                    printPrice11: 25,
                    startNum11: 1,
                    plateFee12: 40,
                    printPrice12: 13,
                    startNum12: 1,

                    weight3: 253,
                    minSize3: 650,
                    maxSize3: 920,
                    plateFee31: 70,
                    printPrice31: 25,
                    startNum31: 1,
                    plateFee32: 40,
                    printPrice32: 13,
                    startNum32: 1,

                    weight6: 254,
                    minSize6: 650,
                    maxSize6: 920,
                    plateFee61: 70,
                    printPrice61: 25,
                    startNum61: 1,
                    plateFee62: 40,
                    printPrice62: 13,
                    startNum62: 1,

                    weight5: 255,
                    minSize5: 650,
                    maxSize5: 920,
                    plateFee51: 70,
                    printPrice51: 25,
                    startNum51: 1,
                    plateFee52: 40,
                    printPrice52: 13,
                    startNum52: 1,
                },

                dataForm: {
                    weight4: '',
                    minSize4: '',
                    maxSize4: '',
                    plateFee41: '',
                    printPrice41: '',
                    startNum41: '',
                    plateFee42: '',
                    printPrice42: '',
                    startNum42: '',

                    weight2: '',
                    minSize2: '',
                    maxSize2: '',
                    plateFee21: '',
                    printPrice21: '',
                    startNum21: '',
                    plateFee22: '',
                    printPrice22: '',
                    startNum22: '',

                    weight1: '',
                    minSize1: '',
                    maxSize1: '',
                    plateFee11: '',
                    printPrice11: '',
                    startNum11: '',
                    plateFee12: '',
                    printPrice12: '',
                    startNum12: '',

                    weight3: '',
                    minSize3: '',
                    maxSize3: '',
                    plateFee31: '',
                    printPrice31: '',
                    startNum31: '',
                    plateFee32: '',
                    printPrice32: '',
                    startNum32: '',

                    weight6: '',
                    minSize6: '',
                    maxSize6: '',
                    plateFee61: '',
                    printPrice61: '',
                    startNum61: '',
                    plateFee62: '',
                    printPrice62: '',
                    startNum62: '',

                    weight5: '',
                    minSize5: '',
                    maxSize5: '',
                    plateFee51: '',
                    printPrice51: '',
                    startNum51: '',
                    plateFee52: '',
                    printPrice52: '',
                    startNum52: '',
                },

                rules: {
                    weight4: [
                        {required: true, message: '请输入纸张克重(单位：克)', trigger: 'blur'},
                        // {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                    ],
                    minSize4: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    maxSize4: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    plateFee41: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice41: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum41: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],
                    plateFee42: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice42: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum42: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],


                    weight2: [
                        {required: true, message: '请输入纸张克重(单位：克)', trigger: 'blur'},
                        // {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                    ],
                    minSize2: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    maxSize2: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    plateFee21: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice21: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum21: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],
                    plateFee22: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice22: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum22: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],


                    weight1: [
                        {required: true, message: '请输入纸张克重(单位：克)', trigger: 'blur'},
                        // {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                    ],
                    minSize1: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    maxSize1: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    plateFee11: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice11: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum11: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],
                    plateFee12: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice12: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum12: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],


                    weight3: [
                        {required: true, message: '请输入纸张克重(单位：克)', trigger: 'blur'},
                        // {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                    ],
                    minSize3: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    maxSize3: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    plateFee31: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice31: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum31: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],
                    plateFee32: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice32: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum32: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],


                    weight6: [
                        {required: true, message: '请输入纸张克重(单位：克)', trigger: 'blur'},
                        // {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                    ],
                    minSize6: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    maxSize6: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    plateFee61: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice61: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum61: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],
                    plateFee62: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice62: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum62: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],


                    weight5: [
                        {required: true, message: '请输入纸张克重(单位：克)', trigger: 'blur'},
                        // {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                    ],
                    minSize5: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    maxSize5: [
                        {required: true, message: '请输入开料尺寸(单位：mm)', trigger: 'blur'}
                    ],
                    plateFee51: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice51: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum51: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ],
                    plateFee52: [
                        {required: true, message: '请输入板材费用', trigger: 'blur'}
                    ],
                    printPrice52: [
                        {required: true, message: '请输入印刷工价', trigger: 'blur'}
                    ],
                    startNum52: [
                        {required: true, message: '请输入起印数量', trigger: 'blur'}
                    ]
                },

                userfatherid: '',
            };
        },
        created() {
            let obj = JSON.parse(window.sessionStorage.getItem("shoppingInfo"))
            console.log(obj.userfatherid)
            this.userfatherid = obj.userfatherid;

            this.inits();
        },
        methods: {

            // 保存印刷报价参数配置
            submit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let data = {
                            value1: JSON.stringify(this.dataForm),
                            userfatherid: this.userfatherid
                        }
                        console.log(this.dataForm)
                        api.editPrintParamData(data).then((res) => {
                            console.log('印刷报价参数配置', res)
                            if (res == success) {
                                this.$message({
                                    message: "保存成功",
                                    type: "success",
                                });
                                // 获取印刷报价参数配置
                                this.inits();
                            } else {
                                this.$message.error(res.message);
                            }
                        }).catch((error) => {
                            console.error("error", error);
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            // 获取印刷报价参数配置
            inits() {
                api.getPrintParamData(this.userfatherid).then((res) => {
                    console.log('印刷报价参数配置', res)

                    Object.keys(this.dataForm).forEach(item => {
                        this.dataForm[item] = res[item]
                    })

                    console.log(this.dataForm)

                }).catch((error) => {
                    console.error("error", error);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .card {
        width: 100%;

        .card-top {
            display: flex;
            align-items: center;
            height: 50px;
            border-bottom: 1px solid #e6e6e6;
            padding: 0 30px;
            line-height: 50px;
            margin-bottom: 10px;

            span {
                display: inline-block;
                width: 5px;
                height: 17px;
                background: #058af2;
                margin-right: 20px;
            }

            h3 {
                color: #058af2;
            }
        }
    }

    table {
        text-align: center;
        border: 1px solid #EBEEF5;

        tr {
            font-size: 14px;
            border-bottom: 1px solid #EBEEF5;

            th {
                color: #909399;
                text-align: center;
                padding: 12px 10px;
                border-right: 1px solid #EBEEF5;
                border-bottom: 1px solid #EBEEF5;
            }

            td {
                text-align: center;
                padding: 12px 10px;
                border-right: 1px solid #EBEEF5;
                border-bottom: 1px solid #EBEEF5;

                .text {
                    padding: 5px;
                }

                ::v-deep .el-input {
                    .el-input__inner {
                        text-align: center;
                    }
                }
            }

            td:last-child {
                border-right: none;
            }
        }

        tr:first-child {
            th:last-child {
                border-right: none;
            }

            th:first-child {
                width: 100px;
            }
        }

        tr:nth-child(12) {
            th {
                border-bottom: none;
            }
        }

        tr:last-child {
            border-bottom: none;

            th {
                border-right: none;
                border-bottom: none;
            }

            td {
                border-right: none;
                border-bottom: none;
            }
        }
    }

    .btn {
        display: flex;
        margin: 10px auto;
    }

    .el-form-item {
        margin-bottom: 0 !important;
    }
</style>
