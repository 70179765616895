<template>
  <div class="WebAdmin">
    <div class="nav">
      <div class="bans">
        <ul class="nav-top">
          <li class="yinyou">
            <span class="myMember">网站管理</span>
          </li>
          <li v-for="item in tabList" :key="item.id" @click="changes(item)">
            <span>{{ item.text }}</span>
            <i :class="chiecked1 === item.id ? 'chiecked' : ''"></i>
          </li>
        </ul>
      </div>
    </div>
    <div class="ban">
      <div class="card-left">
        <div class="top">
          <i class="iconfont icon-iconcopy"></i>
          <span>{{ title }}</span>
        </div>
        <ul class="infos">
          <li
            class="info-item"
            :class="checked === item.id ? 'checked' : ''"
            v-for="item in list"
            :key="item.id"
            @click="replace(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="card-right">
        <!-- 首页维护 -->
        <index-manage v-if="checked === 0"></index-manage>
        <!-- 公告管理 -->
        <notice-manage v-else-if="checked === 1"></notice-manage>
        <!-- 订单审批 -->
        <order-manage v-else-if="checked === 2"></order-manage>
        <!-- 待收款管理 -->
        <collected-manage v-else-if="checked === 3"></collected-manage>
        <!-- 发票管理 -->
        <invoice-manage v-else-if="checked === 4"></invoice-manage>
        <!--信息管理-->
        <information-manage v-else-if="checked === 5"></information-manage>
        <!--投诉管理-->
        <complaint-manage
          ref="complaint"
          v-else-if="checked === 6"
        ></complaint-manage>
        <!-- 报表管理 -->
        <order-report v-else-if="checked === 7"></order-report>
        <!-- 材料发布管理 -->
        <material-manage v-else-if="checked === 8"></material-manage>
        <!-- 印色管理 -->
        <cpd-mange v-else-if="checked === 9"></cpd-mange>

        <!-- 印刷报价配置 -->
        <offer-configure v-else-if="checked === 12"></offer-configure>
        <!-- 充值流水 -->
        <recharge-water v-else-if="checked === 13"></recharge-water>

        <member-upgrade ref="memberUpgrade" v-else></member-upgrade>
        <!-- 信用额度管理 -->
        <!-- <quota-manage v-else></quota-manage> -->
      </div>
    </div>
  </div>
</template>

<script>
import indexManage from "../../components/admins/indexManage";
import noticeManage from "../../components/admins/noticeManage";
import orderManage from "../../components/admins/orderManage";
import invoiceManage from "../../components/admins/invoiceManage";
import informationManage from "../../components/admins/informationManage";
import complaintManage from "../../components/admins/complaintManage";
import orderReport from "@/components/admins/orderReport";
import materialManage from "@/components/admins/materialManage";
import cpdMange from "@/components/admins/cpdMange";
import offerConfigure from "@/components/admins/offerConfigure";
import rechargeWater from "@/components/admins/rechargeWater";
import collectedManage from "../../components/admins/collectedManage";
import quotaManage from "@/components/admins/quotaManage";
import memberUpgrade from "../../components/admins/memberUpgrade.vue";
export default {
  name: "WebAdmin",
  components: {
    indexManage,
    noticeManage,
    orderManage,
    // memberManage,
    invoiceManage,
    informationManage,
    complaintManage,
    orderReport,
    materialManage,
    cpdMange,
    offerConfigure,
    rechargeWater,
    collectedManage,
    quotaManage,
    memberUpgrade,
  },
  data() {
    return {
      title: "网站管理",
      chiecked1: 0,
      checked: 0,
      tabList: [
        {
          id: 0,
          text: "网站维护",
        },
        {
          id: 1,
          text: "订单审批",
        },
        {
          id: 2,
          text: "配置管理",
        },
        {
          id: 3,
          text: "投诉管理",
        },
        {
          id: 4,
          text: "会员申请",
        },
        {
          id: 5,
          text: "充值汇总",
        },
      ],
      webInfo: [
        {
          id: 0,
          text: "首页管理",
          type: 0,
        },
        {
          id: 1,
          text: "公告管理",
          type: 0,
        },
        {
          id: 2,
          text: "订单审批",
          type: 1,
        },
        {
          id: 3,
          text: "待收款订单管理",
          type: 1,
        },
        {
          id: 4,
          text: "开票审核",
          type: 1,
        },
        {
          id: 5,
          text: "信息管理",
          type: 0,
        },
        {
          id: 6,
          text: "投诉处理",
          type: 3,
        },
        // {
        //   id: 7,
        //   text: "报表管理",
        //   type: 4,
        // },
        {
          id: 8,
          text: "材料价格管理",
          type: 2,
        },
        {
          id: 9,
          text: "印色管理",
          type: 2,
        },
        {
          id: 12,
          text: "印刷报价配置",
          type: 2,
        },
        {
          id: 13,
          text: "充值流水",
          type: 5,
        },
        {
          id: 10,
          text: "信用额度管理",
        },
        {
          id: 11,
          text: "会员升级管理",
          type: 4,
        },
      ],
    };
  },
  watch: {
    chiecked1(v) {
      console.log("监听", v);
      if (v === 0) {
        this.checked = 0;
        this.title = "网站管理";
      } else if (v === 1) {
        this.checked = 2;
        this.title = "订单审批";
      } else if (v === 2) {
        this.checked = 8;
        this.title = "配置管理";
      } else if (v === 3) {
        this.checked = 6;
        this.title = "投诉管理";
      } else if (v === 5) {
        this.checked = 13;
        this.title = "充值列表";
      } else {
        this.checked = 11;
        this.title = "会员管理";
        this.$nextTick(() => {
          this.$refs.memberUpgrade.getList();
        });
      }
    },
  },
  computed: {
    list() {
      if (this.chiecked1 === 0) {
        return this.webInfo.filter((el) => el.type === 0);
      } else if (this.chiecked1 === 1) {
        return this.webInfo.filter((el) => el.type === 1);
      } else if (this.chiecked1 === 2) {
        return this.webInfo.filter((el) => el.type === 2);
      } else if (this.chiecked1 === 3) {
        return this.webInfo.filter((el) => el.type === 3);
      }  else if (this.chiecked1 === 5) {
        return this.webInfo.filter((el) => el.type === 5);
      } else {
        return this.webInfo.filter((el) => el.type === 4);
      }
    },
  },
  created() {
    this.chiecked1 = 0;
  },
  methods: {
    replace(item) {
      this.checked = item.id;
      console.log(item);
      if (item.id === 6) {
        this.$refs.complaint.getcomplaint();
      } else if (item.id === 11) {
        this.$nextTick(() => {
          this.$refs.memberUpgrade.getList();
        });
      }
    },
    // 动态添加下标
    changes(item) {
      this.chiecked1 = item.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.WebAdmin {
  background-color: #f8f8f8;
  padding-bottom: 10px;
  font-size: 16px;
  .nav {
    width: 100%;
    background-color: #0289f2;
    margin-bottom: 20px;
    .bans {
      width: 1200px;
      margin: 0 auto;
      .nav-top {
        display: flex;
        li {
          width: 160px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          color: #fff;
          position: relative;
          cursor: pointer;
          .chiecked {
            width: 12px;
            height: 8px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: url("../../static/imgs/icon_triangle_white.png")
              no-repeat center;
            background-size: cover;
          }
        }
        .yinyou {
          width: 160px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          background-color: #047bd7;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // .img {
          //   display: block;
          //   width: 98px;
          //   height: 23px;
          //   background: url("../../static/imgs/personalCenter/mineyy.png")
          //     no-repeat center;
          // }
          .myMember {
            display: block;
            font-size: 20px;
            font-weight: 700;
            color: #fff;
          }
        }
      }
    }
  }
}
.ban {
  width: 1200px;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .card-left {
    width: 200px;
    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;
      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }
    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }
      li:last-child {
        border-bottom: none;
      }
      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .card-right {
    width: 980px;
  }
}
</style>
