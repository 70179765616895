<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>订单管理列表</h3>
      </div>
      <!-- 筛选条件 -->
      <div class="screen">
        <el-row :gutter="10">
          <el-col :span="14" class="colls">
            <span>按日期查询:</span>
            <el-date-picker
              v-model="startTime"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <div class="kuoz">-</div>
            <el-date-picker
              v-model="endtime"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="8" style="line-height: 40px; margin-top: 15px">
            <el-select
              placeholder="请选择订单支付方式"
              v-model="whetherFullPayment"
              clearable
            >
              <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in options2"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="5" class="coll">
            <el-input
              placeholder="请选择客户"
              v-model="orderuserName"
              @focus="changeMember"
            ></el-input>
            <!-- <el-select
                    placeholder="请选择客户"
                    v-model="orderType"
                    clearable
                  >
                    <el-option
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      v-for="item in options"
                    >
                    </el-option>
                  </el-select> -->
          </el-col>
          <el-col :span="5">
            <el-select
              placeholder="请选择订单支付状态"
              v-model="orderPayType"
              clearable
            >
              <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in options1"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-form>
            <el-col :span="7">
              <el-form-item>
              <el-input
                        placeholder="请输入订单编号"
                        v-model="orderId"
                        clearable
                      ></el-input>
                <el-select
                  filterable
                  placeholder="请选择客服"
                  v-model="customerID"
                  clearable
                >
                  <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    v-for="item in options"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

            </el-col>
            <el-col :span="8" style="display: flex">
              <el-form-item>
                <el-button @click="query" class="btn" type="primary"
                  >查询
                </el-button>
              </el-form-item>
              <el-form-item>
                <el-button @click="refresh" class="btn">刷新</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <div class="tables">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column label="订单详情" width="500" header-align="center">
            <template slot-scope="scope">
              <div class="top">
                下单时间：<span>{{ scope.row.ordertime }}</span> 订单编号：<span
                  >{{ scope.row.id }}</span
                >
              </div>
              <div class="box">
                <div class="img">
                  <img :src="picUrls + scope.row.productimage" alt="" />
                </div>
                <div class="info">
                  <p class="p1">产品名称:{{ scope.row.ordername }}</p>
                  <p class="p3">
                    产品类型:<span> {{
                      scope.row.productname
                    }}</span>
                  </p>
                  <p class="p3">
                    印刷数量：<span>{{ scope.row.quantitydemanded }}</span>
                  </p>

                  <p class="p3">
                    订单金额：<span>{{ scope.row.discountfee }} </span>
                    <i
                      v-if="scope.row.payStruts === 3"
                      class="el-icon-edit type"
                      @click="editMoney(scope.row)"
                    ></i>
                  </p>

                  <p class="p3">
                    开票类型：<span>{{ scope.row.invoiceName }} </span>
                  </p>

                </div>
                <div class="userInfo">
                  <p>
                    用户名称:
                    <span class="span1">{{ scope.row.customername }}</span>
                  </p>
                  <p>
                    联系电话:
                    <span class="span2">{{ scope.row.contacttel }}</span>
                  </p>
                  <p class="p3" v-if="scope.row.whetherFullPayment !== null">
                    付款方式：<span v-if="scope.row.payStruts === 5">{{
                      scope.row.whetherFullPayment === 0
                        ? "全款"
                        : scope.row.whetherFullPayment === 1
                        ? "30% 预付款"
                        : "线下付款"
                    }}</span>
                    <span v-else style="color: #ff0000">未支付</span>
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单状态" width="300" header-align="center">
            <template slot-scope="scope">
              <div class="top">
                生产状态：<span>{{ typeFunc(scope.row.status) }}</span>
                支付状态：<span>{{
                  scope.row.payStruts === 3 ? "待支付" : "已支付"
                }}</span>
              </div>
              <div class="box types">
                <!-- <p>待上传印刷文件</p> -->
                <el-button
                  v-if="fileConfig"
                  @click="lookFile(scope.row)"
                  type="primary"
                  :disabled="scope.row.uploadEntry <= 0"
                  >{{ scope.row.uploadEntry > 0 ? "查看文件" : "未上传文件" }}
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" fixed="right">
            <template slot-scope="scope">
              <div class="top">
                <el-button type="primary" size="small" @click="track(scope.row)"
                  ><i class="el-icon-time"></i>订单详情</el-button
                >
              </div>
              <div class="box types">
                <!-- <el-button
                  class="btn"
                  type="primary"
                  size="mini"
                  :disabled="scope.row.status !== '1'"
                  >{{
                    scope.row.status !== "1" ? "审核已通过" : "审核"
                  }}</el-button
                > -->
                <el-button
                  class="btn"
                  type="primary"
                  size="mini"
                  v-if="scope.row.status == '5'"
                  @click="send(scope.row)">发货</el-button>
                <!--                  v-if="scope.row.status == '5'"-->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.currentPage"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog title="审核订单" :visible.sync="dialogVisible" width="30%">
        <div class="topIpt" v-if="radio != '审核通过'">
          <span>审核意见：</span>
          <el-input
            type="textarea"
            autosize
            placeholder="请输入审核内容"
            v-model="textarea"
            class="ipt"
          >
          </el-input>
        </div>
        <!--        <div class="topIpt">-->
        <!--          <span>审核人：</span>-->
        <!--          <el-input-->
        <!--            class="ipt"-->
        <!--            v-model="input"-->
        <!--            placeholder="当前审核人"-->
        <!--          ></el-input>-->
        <!--        </div>-->
        <div class="bottom">
          <!--          <el-switch-->
          <!--            style="display: block"-->
          <!--            v-model="value2"-->
          <!--            active-color="#13ce66"-->
          <!--            inactive-color="#ff4949"-->
          <!--            active-value="0"-->
          <!--            inactive-value="7"-->
          <!--          >-->
          <!--          </el-switch>-->
          <!--          <span>{{ value2 === "0" ? "审核通过" : "审核不通过" }}</span>-->

          <el-radio-group v-model="radio">
            <el-radio label="审核通过">审核通过</el-radio>
            <el-radio label="审核驳回">审核驳回</el-radio>
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="sure">确 定</el-button>
        </span>
      </el-dialog>

      <!--订单详情-->
      <el-dialog
        :visible.sync="detail"
        class="detail"
        :title="title"
        width="55%"
      >
        <order-details
          :orderId="datailOrderId"
          :ordersInfo="orderInfo"
          :key="timer"
          @changesDigs="changes"
        ></order-details>
      </el-dialog>
      <!-- 编辑订单金额 -->
      <el-dialog title="修改订单价格" :visible.sync="editMoneys" width="30%">
        <el-input v-model="money" placeholder="请输入需要修改的金额"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editMoneys = false">取 消</el-button>
          <el-button type="primary" @click="sureEdit">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 发货 -->
      <el-dialog title="发货" :visible.sync="sendOrder" width="40%">
         <p>请确认当前订单的收货地址</p>
           <el-card class="box-card" style="margin-top: 10px;">
            <div slot="header" class="clearfix">
              <span>收货人信息</span>
             </div>
            <div  class="text item">
              <el-form :model="postForm.contactInfoList[1]">
                <el-form-item label="联系 人"  >
                  <el-input v-model="postForm.contactInfoList[1].contact" style="width: 450px;"  ></el-input>
                </el-form-item>
                <el-form-item label="电    话" >
                  <el-input v-model="postForm.contactInfoList[1].mobile" style="width: 450px;" ></el-input>
                </el-form-item>
                <el-form-item label="收货地址" >
                  <el-input v-model="postForm.contactInfoList[1].address"  style="width: 450px;"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <el-card class="box-card" >
            <div slot="header" class="clearfix">
              <span>寄件人信息</span>
            </div>
            <div  class="text item">
              <el-form :model="postForm.contactInfoList[0]">
                <el-form-item label="联系 人"  >
                  <el-input v-model="postForm.contactInfoList[0].contact" style="width: 450px;" ></el-input>
                </el-form-item>
                <el-form-item label="电    话" >
                  <el-input v-model="postForm.contactInfoList[0].mobile" style="width: 450px;" ></el-input>
                </el-form-item>
                <el-form-item label="寄件地址" >
                  <el-input v-model="postForm.contactInfoList[0].address" style="width: 450px;" ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-card>

         <span slot="footer" class="dialog-footer">
          <el-button @click="sendOrder = false">取 消</el-button>
          <el-button type="primary" @click="sureSend">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 查看文件弹出层 -->

      <el-dialog
        :visible.sync="dialogVisible3"
        title="查看文件"
        width="55%"
        class="file"
        @close="closes"
      >
        <div class="upFile">
          <div class="top">
            <p>订单编号：{{ orderInfos.id }}</p>
            <p>产品名称：{{ orderInfos.ordername }}</p>
            <div class="date">
              <span>文件上传日期：</span>
              <el-date-picker
                format="yyyy-MM-dd"
                placeholder="选择日期"
                type="date"
                v-model="upTime"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-button class="btn" type="primary" @click="queryList"
                >查询</el-button
              >
            </div>
          </div>
          <div class="tableList" v-if="fileData.length > 0">
            <el-table :data="fileData" border style="width: 100%">
              <el-table-column
                align="center"
                header-align="center"
                label="上传时间"
                prop="createTime"
              ></el-table-column>
              <el-table-column
                align="center"
                header-align="center"
                label="文件名"
                prop="orderFileName"
              ></el-table-column>
              <el-table-column
                align="center"
                header-align="center"
                label="状态"
              >
                <template slot-scope="scope">
                  <span
                    :class="
                      scope.row.orderFileStruts == '1' ? 'success' : 'error'
                    "
                    >{{
                      scope.row.orderFileStruts == "1" ? "已上传" : "上传失败"
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                header-align="center"
                label="操作"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="browse(scope.row)"
                    size="mini"
                    type="warning"
                    class="browse"
                  >
                    <a class="liulan" :href="BrowseUrl" target="_blank">浏览</a>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="tableList" v-else>
            <el-table :data="fileData" border style="width: 100%">
              <el-table-column
                align="center"
                header-align="center"
                label="上传时间"
                prop="createTime"
              ></el-table-column>
              <el-table-column
                align="center"
                header-align="center"
                label="文件名"
                prop="orderFileName"
              ></el-table-column>
              <el-table-column
                align="center"
                header-align="center"
                label="状态"
              >
                <template slot-scope="scope">
                  <span
                    :class="
                      scope.row.orderFileStruts == '1' ? 'success' : 'error'
                    "
                    >{{
                      scope.row.orderFileStruts == "1" ? "已上传" : "上传失败"
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                header-align="center"
                label="操作"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="browse(scope.row)"
                    size="mini"
                    type="warning"
                    class="browse"
                  >
                    <a class="liulan" :href="BrowseUrl" target="_blank">浏览</a>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="pages">
            <el-pagination
              :current-page="currentPagefile"
              :page-size="pageSizefile"
              :page-sizes="[5, 10, 20, 30]"
              :total="filetotal"
              @current-change="handleCurrentfileChange"
              @size-change="handleSizefileChange"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 客户弹框 -->
    <div class="dialogs">
      <el-dialog
        :close-on-click-modal="false"
        title="选择客户"
        :visible.sync="dialogVisible4"
        @close="closed"
      >
        <div class="queryMember">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-input
                v-model.trim="custName1"
                placeholder="请输入用户名称"
              ></el-input
            ></el-col>
            <el-col :span="8">
              <el-button type="primary" @click="queryMember">查询</el-button>
              <el-button @click="refMember">刷新</el-button>
            </el-col>
          </el-row>
        </div>
        <el-table
          :data="allmemberList"
          border
          style="width: 100%"
          class="memberTable"
        >
          <el-table-column header-align="center" align="center" width="100">
            <template scope="scope">
              <el-radio
                :label="scope.row.user_ID"
                v-model="radioMember"
                @change.native="getCurrentRow(scope.row)"
              ></el-radio>
            </template>
          </el-table-column>

          <el-table-column
            property="name"
            label="用户名称"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="联系方式"
            width="180"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.phone ? scope.row.phone : scope.row.username
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            property="createTime"
            label="注册时间"
            width="200"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination
            @size-change="handleSizeChange4"
            @current-change="handleCurrentChange4"
            :current-page="pageNumber3"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize3"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total4"
          >
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelChange">取 消</el-button>
          <el-button type="primary" @click="sureChange">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "../../api/webAdmin/webAdmin";
import apiList from "../../api/invoice/invoice";
import OrderDetails from "../OrderDetails/OrderDetails";
import picUrl from "../../api/picture";

export default {
  name: "orderManage",
  components: {
    OrderDetails,
  },
  data() {
    return {
      radioMember: "",
      dialogVisible4: false, // 客户弹框
      allmemberList: [], // 弹框客户列表
      pageNumber3: 1,
      pageSize3: 5,
      total4: 0,
      custName1: "", // 客户弹窗筛选
      customerID: "", //筛选条件客服id
      orderUserId: "", // 筛选条件的客户id
      orderuserName: "", // 客户名称
      options2: [
        // 订单支付状态
        {
          value: "0",
          label: "全款",
        },
        {
          value: "1",
          label: "30%预付款",
        },
        {
          value: "2",
          label: "线下付款",
        },
      ],
      whetherFullPayment: "",
      //浏览地址
      BrowseUrl: "",
      fileData: [],
      filetotal: 0,
      pageSizefile: 5,
      currentPagefile: 1,
      upTime: "",
      dialogVisible3: false,
      sendOrder: false,
      money: "", //修改订单价格
      editMoneys: false, // 编辑订单价格弹框
      value2: "0",
      textarea: "",
      input: "",
      dialogVisible: false,
      startTime: "", // 开始时间
      endtime: "", //结束时间
      cpid: "", // 模糊查询
      pages: {
        pageSize: 5,
        currentPage: 1,
      },
      total: 0,
      tableData: [],
      orderType: "", // 订单生产状态筛选
      orderPayType: "", // 订单支付状态筛选
      options1: [
        // 订单支付状态
        {
          value: "3",
          label: "待支付",
        },
        {
          value: "5",
          label: "已支付",
        },
      ],
      options: [
        // 订单生产状态
      ],
      orderId: "", // 订单id
      fileConfig: false,

      detail: false, // 订单详情弹窗打开
      title: "", //订单详情弹窗打开标题
      orderInfo: {},
      orderInfos: {},
      timer: "",

      //审核通过
      radio: "审核通过",
      picUrls: "",
      userId: "", // 当前管理员id
      userType: "",
      datailOrderId: "", // 详情弹框订单id

      postForm: {
        language: 'zh-CN',
        cargoDetails: [{name: ''}],
        contactInfoList : [
          {
            contactType: '1',
            contact: '',
            mobile: '',
            country: 'CN',
            address: ''
          },
          {
            contactType: '2',
            contact: '',
            mobile: '',
            country: 'CN',
            address: ''
          }
        ],
        monthlyCard: '0255047607',
        expressTypeId: 2,
        orderId: ''
      }
    };
  },
  created() {
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (obj) {
      this.userId = obj.user_ID;
      this.userType = obj.userType;
    }

    this.picUrls = picUrl.picUrl;
    this.getService();
    this.init();
    this.getConfig();

  },
  methods: {

    cancelChange() {
      this.radioMember = "";
      this.orderUserId = "";
      this.orderuserName = "";
      this.dialogVisible4 = false;
    },
    sureChange() {
      if (this.radioMember === "") {
        this.$message.warning("请选择客户！");
        return;
      }
      this.dialogVisible4 = false;
    },
    // 单选
    getCurrentRow(item) {
      console.log(item);
      this.orderUserId = item.customerId;
      this.orderuserName = item.name;
    },
    queryMember() {
      this.getCustomer();
    },
    refMember() {
      this.custName1 = "";
      this.pageNumber3 = 1;
      this.pageSize3 = 5;
      this.getCustomer();
    },
    // 弹窗关闭操作
    closed() {
      if (!this.dialogVisible4) {
        // this.dialogVisible3 = false;
        if (this.radioMember === "") {
          this.orderUserId = ""; // 选择客户id
          this.custName1 = ""; // 筛选条件清空
          this.orderuserName = ""; //选择客户名称
        }
      }
    },
    // 获取客户
    getCustomer() {
      api
        .queryUser(
          this.pageNumber3,
          this.pageSize3,
          "",
          "",
          this.custName1,
          "0"
        )
        .then((res) => {
          console.log("获取客户", res);
          if (res.success) {
            // this.allList = res.data.userPageInfo.list;
            this.allmemberList = res.data.list;
            this.total4 = res.data.total;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 选择客户
    changeMember() {
      this.custName1 = "";
      this.dialogVisible4 = true;
      this.getCustomer();
    },
    // 获取所有客服
    getService() {
      api.getAllUserDontExistCustom().then((res) => {
        console.log("获取客服", res);
        if (res.success && res.code == 200) {
          res.data.userPageInfo.forEach((el) => {
            el.label = el.name;
            el.value = el.user_ID;
          });
          this.options = res.data.userPageInfo;
        } else {
          this.$message.error("获取客服失败");
        }
      });
    },
    // 判断生产状态
    typeFunc(v) {
      let text = "";
      if (v === "-1") {
        return (text = "待核价");
      } else if (v === "1") {
        return (text = "待审核");
      } else if (v === "2") {
        return (text = "待排产");
      } else if (v === "3") {
        return (text = "生产中");
      } else if (v === "4") {
        if (this.userType === 0) {
          return (text = "生产完成");
        } else {
          return (text = "待入库");
        }
      } else if (v === "5") {
        if (this.userType === 0) {
          return (text = "待发货");
        } else {
          return (text = "已入库");
        }
      } else if (v === "6") {
        if (this.userType === 0) {
          return (text = "已发货");
        } else {
          return (text = "待签收");
        }
      } else if (v === "7") {
        return (text = "订单取消");
      } else if (v === "8") {
        return (text = "订单完成(已评价)");
      } else if (v === "9") {
        return (text = "待印前制作");
      } else if (v === "10") {
        return (text = "待客确认");
      } else if (v === "11") {
        return (text = "已收货");
      } else {
        return text;
      }
    },
    closes() {
      this.upTime = "";
    },
    //浏览
    browse(row) {
      console.log(row);
      this.BrowseUrl = picUrl.BrowseUrl + row.orderFileDir;
    },
    // 获取文件列表分页
    getUpLoadFilesList() {
      this.$http
        .get(
          "/orderfileinfo?pageSize=" +
            this.pageSizefile +
            "&pageNumber=" +
            this.currentPagefile +
            "&orderId=" +
            this.orderInfos.id +
            "&createTime=" +
            this.upTime
        )
        .then((res) => {
          console.log("获取文件列表分页", res);
          console.log(res.data.data.list);
          this.fileData = res.data.data.list;
          this.filetotal = res.data.data.total;
        });
    },
    // 查询文件列表
    queryList() {
      //获取文件列表分页
      this.getUpLoadFilesList();
    },
    lookFile(item) {
      console.log(item);
      this.orderInfos = item;
      this.dialogVisible3 = true;
      this.getUpLoadFilesList();
    },
    // 刷新
    refresh() {
      this.startTime = "";
      this.endtime = "";
      this.custName1 = "";
      this.orderuserName = "";
      this.customerID = "";
      this.orderUserId = "";
      this.orderPayType = "";
      this.whetherFullPayment = "";
      this.init();
    },
    changes() {
      // console.log("触发了父组件事件");
      this.init();
      this.detail = false;
    },
    // 发货
    send(item) {
      if (item.status === "1") {
        this.$message.warning("请先审核,审核通过后才能发货!");
        return;
      }
      console.log(item);
      this.orderId = item.id;
      this.postForm.cargoDetails[0].name = item.ordername +' ' + item.quantitydemanded +'张'
      this.postForm.orderId = this.orderId

      api
          .queryEnterprise()
          .then((res) => {
            if (res.success) {
              this.postForm.contactInfoList = [
                  {
                    contactType: '1',
                    contact: res.data.corporate,
                    mobile: res.data.contactNumber,
                    country: 'CN',
                    address: res.data.address
                  },{
                    contactType: '2',
                    contact: item.contacts,
                    mobile: item.telephone,
                    country: 'CN',
                    address: item.resiveAddr,
                  }
              ]
              this.sendOrder = true;
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
    },
    // 确认发货
    sureSend() {

      api
          .sfOrder(this.postForm)
          .then((res) => {
             if(res.apiResultCode ==='A1000' &&  JSON.parse(res.apiResultData).errorCode ==='S0000'){
               const sfcode = JSON.parse(res.apiResultData).msgData.waybillNoInfoList[0].waybillNo
               if(sfcode){
                 api
                     .sfPDFFile({documents: [{masterWaybillNo:sfcode}]})
                     .then((res) => {
                       if(res.success && res.code == 200) {
                        window.open(res.data.path, '_blank');
                         let data = {
                           status: "6",
                           expressTrackingNumber: res.data.waybillNo,
                           expressTrackingDir: res.data.dir,
                           expressTrackingPath:res.data.path,
                         };
                         api
                             .orderSend(this.orderId, data)
                             .then((res) => {
                               if (res.success) {
                                 this.init();
                                 this.sendOrder = false;
                               }
                             })
                             .catch((error) => {
                               console.error("error", error);
                             });
                       } else {
                         this.$message.warning("获取面单失败",res.message);
                       }
                     })

               }
            } else {
               this.$message.warning("顺丰下单失败 ",JSON.parse(res.apiResultData).errorMsg);
             }
          });

    },
    // 确定修改订单总额
    sureEdit() {
      if (this.money === "") {
        this.$message.warning("请输入需要修改的金额");
        return;
      }
      api
        .orderEditPrice(this.orderId, this.money)
        .then((res) => {
          // console.log("修改用户信息", res);
          if (res.success) {
            this.$message.success("修改成功");
            this.init();
            this.editMoneys = false;
            this.orderId = "";
          } else {
            this.$message.error("修改失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 编辑订单总额弹窗
    editMoney(item) {
      console.log(item);
      this.editMoneys = true;
      this.orderId = item.id;
      this.money = item.discountfee;
    },
    // 取消
    cancel() {
      this.orderId = "";
      this.textarea = "";
      this.input = "";
      this.dialogVisible = false;
    },
    // 确定
    sure() {
      // if (this.textarea === "") {
      //   this.$message.warning("请填写审核意见");
      //   return;
      // }
      // if (this.input === "") {
      //   this.$message.warning("请填写审核人");
      //   return;
      // }
      let data = {
        auditopinion: this.textarea,
        auditopinionaccout: this.userId,
        // stauts: this.value2,
        status: this.radio == "审核通过" ? 0 : 9,
      };
      api
        .orderExamine(this.orderId, data)
        .then((res) => {
          console.log("审核", res);
          if (res.success) {
            this.$message.success("审核完成");
            this.cancel();
            this.init();
          } else {
            this.$message.error("审核失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 审核订单
    applyInvoice(item) {
      console.log(item);
      this.dialogVisible = true;
      this.orderId = item.id;
      this.input = item.customername;
    },
    // 查询
    query() {
      // if (
      //   this.startTime === "" &&
      //   this.endtime === "" &&
      //   this.cpid === "" &&
      //   this.orderType === "" &&
      //   this.orderPayType === ""
      // ) {
      //   this.$message.warning("请输入查询参数");
      //   return;
      // }
      this.init();
    },
    // 获取订单列表
    init() {
      apiList
        .queryOrder(
          this.startTime,
          this.endtime,
          this.customerID,
          this.pages.pageSize,
          this.pages.currentPage,
          this.orderUserId,
          this.orderPayType,
          this.orderId,
          this.whetherFullPayment
        )
        .then((res) => {
          // console.log("商城订单", res);
          if (res.success) {
            this.total = res.data.pageInfo.total;
            this.tableData = res.data.pageInfo.list;
          } else {
            this.$message.error("获取订单失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    getConfig() {
      apiList
          .getConfig()
          .then((res) => {
            if (res.success) {
              this.fileConfig = res.data
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
    },
    // 选择客户分页
    handleSizeChange4(val) {
      this.pageSize3 = val;
      this.getCustomer();
    },
    handleCurrentChange4(val) {
      this.pageNumber3 = val;
      this.getCustomer();
    },
    handleClick() {},
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.init();
    },
    handleSizefileChange(val) {
      this.pageSizefile = val;
      this.getUpLoadFilesList();
    },
    handleCurrentfileChange(val) {
      this.currentPagefile = val;
      this.getUpLoadFilesList();
    },
    // 订单详情
    track(item) {
      this.timer = new Date().getTime();
      console.log(item);
      this.datailOrderId = item.id;
      this.orderInfo = item;
      this.title = "订单【" + this.orderInfo.id + "】详情";
      this.detail = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  .screen {
    margin-top: 10px;
    .colls {
      display: flex;
      line-height: 40px;
      margin: 15px 0 15px 48px;
      span {
        margin-right: 5px;
      }
      .kuoz {
        margin: 0 10px;
      }
    }
    .coll {
      display: flex;
      justify-content: space-around;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .card-form {
    display: flex;
    line-height: 40px;
    margin: 20px auto 0;
    margin-right: 20px;
    justify-content: flex-end;
    ::v-deep .el-input__icon {
      height: 0;
    }
    div {
      padding: 0 5px;
    }
  }
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .tab {
    padding: 10px;
  }
  .page {
    margin-top: 20px;
  }
  .tables {
    ::v-deep .el-button {
      margin-bottom: 3px;
    }
    ::v-deep .el-button + .el-button {
      margin-left: 0;
    }
    ::v-deep .el-table .cell {
      padding: 0;
    }
    ::v-deep .el-table .cell,
    ::v-deep .el-table--border td:first-child .cell {
      padding: 0;
    }
    ::v-deep .el-table_1_column_1 {
      padding: 0;
    }
    ::v-deep .el-table td {
      padding: 0;
    }
    .top {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #f8f8f8;
      color: #b4b4b4;
      span {
        color: #000000;
      }
    }
    .box {
      padding: 5px 10px;
      height: 125px;
      display: flex;
      justify-content: space-around;
      .img {
        margin: 4px 15px 0;
        width: 80px;
        height: 80px;
        img {
          display: inline-block;
          width: 80px;
          height: 100%;
        }
      }

      .info {
        width: 35%;
        .p1 {
          color: #409eff;
        }
        .p3 {
          span {
            color: #ff0000;
          }
          .type {
            margin-left: 5px;
          }
        }
      }
      .userInfo {
        .p3 {
          span {
            color: #ff0000;
          }
        }
        line-height: 25px;
        .span1 {
          color: #409eff;
        }
        .span2 {
          color: #ff0000;
        }
      }
    }
    .types {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        padding: 10px 0;
      }
      .btn {
        width: 50%;
      }
    }
  }
  .ipt {
    margin: 5px;
  }
  .bottom {
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }
  .upFile {
    text-align: center;

    .top {
      margin-bottom: 10px;

      .date {
        margin-top: 10px;

        .btn {
          margin-left: 10px;
        }
      }
    }

    .tableList {
      margin-bottom: 10px;

      .success {
        color: #058af2;
      }

      .error {
        color: #ff0000;
      }
    }

    .noTable {
      text-align: center;
      color: #66b1ff;
      margin: 15px;
    }

    .upBtn {
      margin-top: 15px;
    }
    /*操作_浏览按钮*/
    .browse {
      padding: 0;

      .liulan {
        color: white;
        text-decoration: none;
        height: 100%;
        padding: 7px 15px;
        display: block;
        text-align: center;
      }
    }
  }
}
.dialogs {
  width: 100%;
  .queryMember {
    width: 100%;
    margin-bottom: 10px;
  }
  .memberTable {
    ::v-deep .el-radio__label {
      display: none;
    }
  }
}
</style>

<style lang="scss">
/*订单详情*/
.detail {
  .el-dialog {
    height: 75%;
    overflow: auto;

    .el-dialog__header {
      padding: 20px 20px 20px;
      // position: fixed;
      background: #fff;
      width: calc(55% - 40px);
    }

    // .el-dialog__body {
    //   margin-top: 64px;
    // }
  }
}
</style>
