<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>信用额度管理</h3>
      </div>
      <div class="query">
        <el-row :gutter="10">
          <el-col :span="14" class="colls">
            <span>按日期查询:</span>
            <el-date-picker
              v-model="startTime"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <div class="kuoz">-</div>
            <el-date-picker
              v-model="endTime"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="6" class="coll">
            <el-button type="primary" @click="query">查询</el-button>
            <el-button @click="refreshs">刷新</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="tab">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            prop="orderCounts"
            label="客户名称"
            width="199"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="phone"
            label="联系方式"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="orderSumMoney"
            label="额度"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="orderProcessingFee"
            label="已用"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="orderPaperFee"
            label="未用"
            align="center"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/webAdmin/webAdmin";
export default {
  name: "orderReport",
  data() {
    return {
      startTime: "",
      endTime: "",
      tableData: [],
    };
  },
  created() {
    this.inits();
  },
  methods: {
    // 查询
    query() {
      if (this.startTime === "" || this.endTime === "") {
        this.$message.warning("请输入查询区间");
        return;
      }
      this.inits();
    },
    // 刷新
    refreshs() {
      this.startTime = this.endTime = "";
      this.inits();
    },
    // 获取报表数据
    inits() {
      api
        .report(this.startTime, this.endTime)
        .then((res) => {
          // console.log("获取报表数据", res);
          if (res.success) {
            this.tableData = res.data;
          } else {
            this.$message.err("获取报表失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .query {
    margin-top: 10px;
    .colls {
      display: flex;
      line-height: 40px;
      margin: 15px 48px;
      span {
        margin-right: 5px;
      }
      .kuoz {
        margin: 0 10px;
      }
    }
    .coll {
      line-height: 70px;
    }
  }
}
</style>
