<template>
    <div>
        <div class="card">
            <div class="card-top">
                <span></span>
                <h3>订单管理列表</h3>
            </div>
            <!-- 筛选条件 -->
            <div class="screen">
                <el-row :gutter="10">
                    <el-col :span="14" class="colls">
                        <span>按日期查询:</span>
                        <el-date-picker
                                v-model="startTime"
                                type="date"
                                placeholder="选择日期"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                        <div class="kuoz">-</div>
                        <el-date-picker
                                v-model="endTime"
                                type="date"
                                placeholder="选择日期"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-col>
                    <!--                    <el-col :span="8" style="line-height: 40px; margin-top: 0px">-->
                    <el-col :span="8" class="coll">
                        <el-input
                                placeholder="请选择客户"
                                v-model="orderuserName"
                                @focus="changeMember"
                        ></el-input>
                    </el-col>
                    <!--                    </el-col>-->
                </el-row>
                <el-row :gutter="10">

                    <el-col :span="8" class="colls" style="display: flex">
                        <el-button @click="query"  type="primary">查询</el-button>
                        <el-button @click="refresh"  >刷新</el-button>
                    </el-col>

                </el-row>
            </div>
            <div class="tables">
                <el-table :data="tableData" border>
                  <el-table-column
                      label="人员"
                      header-align="center"
                      align="center">
                    <template slot-scope="scope">
                      <p>{{ scope.row.name }}{{ scope.row.phone }}</p>
                    </template>
                  </el-table-column>
                    <el-table-column
                            label="时间"
                            header-align="center"
                            align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.createTime }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="money"
                            label="金额"
                            header-align="center"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            property="ordeRnumber"
                            label="订单编号"
                            header-align="center"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            property="name"
                            label="钱包波动"
                            header-align="center"
                            align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.type === 1 ? "增加" : "减少" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="extendColumn1"
                            label="支付主题"
                            header-align="center"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            property="address"
                            label="支付状态"
                            header-align="center"
                            align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.extendColumn2 === "0" ? "未支付" : "已支付"}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pages.currentPage"
                        :page-sizes="[5, 10, 20, 30]"
                        :page-size="pages.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                >
                </el-pagination>
            </div>


            <!-- 客户弹框 -->
            <div class="dialogs">
                <el-dialog :close-on-click-modal="false" title="选择客户" :visible.sync="dialogVisible4" @close="closed">
                    <div class="queryMember">
                        <el-row :gutter="10">
                            <el-col :span="8">
                                <el-input
                                        v-model.trim="custName1"
                                        placeholder="请输入用户名称"
                                ></el-input
                                >
                            </el-col>
                            <el-col :span="8">
                                <el-button type="primary" @click="queryMember">查询</el-button>
                                <el-button @click="refMember">刷新</el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <el-table
                            :data="allmemberList"
                            border
                            style="width: 100%"
                            class="memberTable"
                    >
                        <el-table-column header-align="center" align="center" width="100">
                            <template scope="scope">
                                <el-radio
                                        :label="scope.row.user_ID"
                                        v-model="radioMember"
                                        @change.native="getCurrentRow(scope.row)"
                                ></el-radio>
                            </template>
                        </el-table-column>

                        <el-table-column
                                property="name"
                                label="用户名称"
                                header-align="center"
                                align="center"
                        >
                        </el-table-column>
                        <el-table-column
                                label="联系方式"
                                width="180"
                                header-align="center"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <span>{{scope.row.phone ? scope.row.phone : scope.row.username}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                property="createTime"
                                label="注册时间"
                                width="200"
                                header-align="center"
                                align="center"
                        >
                        </el-table-column>
                    </el-table>

                    <div class="page">
                        <el-pagination
                                @size-change="handleSizeChange4"
                                @current-change="handleCurrentChange4"
                                :current-page="pageNumber3"
                                :page-sizes="[5, 10, 20, 30]"
                                :page-size="pageSize3"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="total4"
                        >
                        </el-pagination>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="cancelChange">取 消</el-button>
                        <el-button type="primary" @click="sureChange">确 定</el-button>
                    </span>
                </el-dialog>
            </div>

        </div>
    </div>
</template>

<script>
    import api from "../../api/rechargeWater/rechargeWater";
    // import picUrl from "../../api/picture";

    export default {
        name: "rechargeWater",
        components: {},
        data() {
            return {
                pages: {
                    pageSize: 10,
                    currentPage: 1,
                },
                userId: '',//客户ID：userId
                startTime: '',// 起始时间
                endTime: '',// 截止时间

                total: 0,
                tableData: [],

                pageNumber3: 1,
                pageSize3: 5,

                custName1: "", // 客户弹窗筛选
                dialogVisible4: false, // 客户弹框
                radioMember: "",
                orderuserName: "", // 客户名称

                allmemberList: [], // 弹框客户列表
                total4: 0,

            };
        },
        created() {
            // let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
            // if (obj) {
            //     this.userId = obj.user_ID;
            //     this.userType = obj.userType;
            // }

            // this.picUrls = picUrl.picUrl;
            // this.getService();
            this.init();
        },
        methods: {

            // 充值流水
            showDigs() {
                api.walletInfo(this.pages.currentPage, this.pages.pageSize, 1, 1, this.userId, this.startTime ? this.startTime : '', this.endTime ? this.endTime : '').then((res) => {
                    console.log('充值流水', res)
                    if (res.success) {
                        this.tableData = res.data.list;
                        this.total = res.data.total;
                        // this.showDig = true;
                    } else {
                        this.$message.error("获取失败");
                    }
                }).catch((error) => {
                    console.error("error", error);
                });
            },

            // 获取客户
            getCustomer() {
                api.queryUser(this.pageNumber3, this.pageSize3, "", "", this.custName1, "0").then((res) => {
                    console.log("获取客户", res);
                    if (res.success) {
                        // this.allList = res.data.userPageInfo.list;
                        this.allmemberList = res.data.list;
                        this.total4 = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },

            // 弹窗关闭操作
            closed() {
                if (!this.dialogVisible4) {
                    // this.dialogVisible3 = false;
                    if (this.radioMember === "") {
                        this.userId = ""; // 选择客户id
                        this.custName1 = ""; // 筛选条件清空
                        this.orderuserName = ""; //选择客户名称
                    }
                }
            },

            cancelChange() {
                this.radioMember = "";
                this.userId = "";
                this.orderuserName = "";
                this.dialogVisible4 = false;
            },

            sureChange() {
                if (this.radioMember === "") {
                    this.$message.warning("请选择客户！");
                    return;
                }
                // this.radioMember = "";
                this.dialogVisible4 = false;
            },

            // 选择客户
            changeMember() {
                this.custName1 = "";
                this.dialogVisible4 = true;
                this.getCustomer();
            },

            // 单选
            getCurrentRow(item) {
                console.log(item);
                this.userId = item.user_ID;
                this.orderuserName = item.name + '(' + item.phone + ')';
            },

            // 刷新
            refresh() {
                this.pages.pageSize = 5;
                this.pages.currentPage = 1;
                this.init();
            },

            // 查询
            query() {
                this.init();
            },

            // 会员查询
            queryMember() {
                this.getCustomer();
            },

            //会员刷新
            refMember() {
                this.custName1 = "";
                this.pageNumber3 = 1;
                this.pageSize3 = 5;
                this.getCustomer();
            },

            // 充值流水分页
            handleSizeChange(val) {
                this.pages.pageSize = val;
                this.init();
            },

            handleCurrentChange(val) {
                this.pages.currentPage = val;
                this.init();
            },

            // 选择客户分页
            handleSizeChange4(val) {
                this.pageSize3 = val;
                this.getCustomer();
            },
            handleCurrentChange4(val) {
                this.pageNumber3 = val;
                this.getCustomer();
            },

            // 获取充值流水列表
            init() {
                // 充值流水
                this.showDigs();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .card {
        width: 100%;

        .screen {
            margin-top: 10px;

            .colls {
                display: flex;
                line-height: 40px;
                margin: 0px 0 15px 48px;

                span {
                    margin-right: 5px;
                }

                .kuoz {
                    margin: 0 10px;
                }
            }

            .coll {
                display: flex;
                justify-content: space-around;
            }

            .btn {
                margin-left: 20px;
            }
        }

        .card-form {
            display: flex;
            line-height: 40px;
            margin: 20px auto 0;
            margin-right: 20px;
            justify-content: flex-end;

            ::v-deep .el-input__icon {
                height: 0;
            }

            div {
                padding: 0 5px;
            }
        }

        .card-top {
            display: flex;
            align-items: center;
            height: 50px;
            border-bottom: 1px solid #e6e6e6;
            padding: 0 30px;
            line-height: 50px;

            span {
                display: inline-block;
                width: 5px;
                height: 17px;
                background: #058af2;
                margin-right: 20px;
            }

            h3 {
                color: #058af2;
            }
        }

        .tab {
            padding: 10px;
        }

        .page {
            margin-top: 20px;
        }

        .tables {
            ::v-deep .el-button {
                margin-bottom: 3px;
            }

            ::v-deep .el-button + .el-button {
                margin-left: 0;
            }

            ::v-deep .el-table .cell {
                padding: 0;
            }

            ::v-deep .el-table .cell,
            ::v-deep .el-table--border td:first-child .cell {
                padding: 0;
            }

            ::v-deep .el-table_1_column_1 {
                padding: 0;
            }

            ::v-deep .el-table td {
                padding: 0;
            }

            .top {
                width: 100%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: #f8f8f8;
                color: #b4b4b4;

                span {
                    color: #000000;
                }
            }

            .box {
                padding: 5px 10px;
                height: 102px;
                display: flex;
                justify-content: space-around;

                .img {
                    margin: 4px 15px 0;
                    width: 80px;
                    height: 80px;

                    img {
                        display: inline-block;
                        width: 80px;
                        height: 100%;
                    }
                }

                .info {
                    width: 35%;

                    .p1 {
                        color: #409eff;
                    }

                    .p3 {
                        span {
                            color: #ff0000;
                        }

                        .type {
                            margin-left: 5px;
                        }
                    }
                }

                .userInfo {
                    .p3 {
                        span {
                            color: #ff0000;
                        }
                    }

                    line-height: 25px;

                    .span1 {
                        color: #409eff;
                    }

                    .span2 {
                        color: #ff0000;
                    }
                }
            }

            .types {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p {
                    padding: 10px 0;
                }

                .btn {
                    width: 50%;
                }
            }
        }

        .ipt {
            margin: 5px;
        }

        .bottom {
            margin-top: 5px;
            display: flex;
            justify-content: center;
        }

        .upFile {
            text-align: center;

            .top {
                margin-bottom: 10px;

                .date {
                    margin-top: 10px;

                    .btn {
                        margin-left: 10px;
                    }
                }
            }

            .tableList {
                margin-bottom: 10px;

                .success {
                    color: #058af2;
                }

                .error {
                    color: #ff0000;
                }
            }

            .noTable {
                text-align: center;
                color: #66b1ff;
                margin: 15px;
            }

            .upBtn {
                margin-top: 15px;
            }

            /*操作_浏览按钮*/
            .browse {
                padding: 0;

                .liulan {
                    color: white;
                    text-decoration: none;
                    height: 100%;
                    padding: 7px 15px;
                    display: block;
                    text-align: center;
                }
            }
        }
    }

    .dialogs {
        width: 100%;

        .queryMember {
            width: 100%;
            margin-bottom: 10px;
        }

        .memberTable {
            ::v-deep .el-radio__label {
                display: none;
            }
        }
    }
</style>

<style lang="scss">
    /*订单详情*/
    .detail {
        .el-dialog {
            height: 75%;
            overflow: auto;

            .el-dialog__header {
                padding: 20px 20px 20px;
                // position: fixed;
                background: #fff;
                width: calc(55% - 40px);
            }

            // .el-dialog__body {
            //   margin-top: 64px;
            // }
        }
    }
</style>
