<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>对应印色</h3>
      </div>
      <div class="query">
        <el-input
          type="text"
          v-model.trim="input"
          style="width: 300px; margin-right: 10px"
          placeholder="请输入印色信息"
        ></el-input>
        <el-button type="primary" @click="query">查询</el-button>
        <el-button @click="refreshs">刷新</el-button>
      </div>
      <div class="tab">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            prop="name"
            label="印色名称"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="colorReam"
            label="色令"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="plateNumber"
            label="版数"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="board"
            label="版单价(商城)"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="type"
            label="类型"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              {{
                scope.row.types === 0
                  ? "其他"
                  : scope.row.types === 1
                  ? "彩色（四色）"
                  : scope.row.types === 2
                  ? "单色"
                  : scope.row.types === 3
                  ? "双色"
                  : scope.row.types === 4
                  ? "彩盒"
                  : scope.row.types === 5
                  ? "彩箱"
                  : scope.row.types === 6
                  ? "印刷加工"
                  : scope.row.types === 7
                  ? "装帧加工"
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button type="primary" @click="editPrice(scope.row)"
                >修改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 编辑印色价格 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      @close="close"
      width="30%"
    >
      <div class="ipt">
        <span>印色名称</span>
        <el-input v-model="formLabelAlign.name" disabled></el-input>
      </div>
      <div class="ipt">
        <p>版单价</p>
        <el-input-number
          v-model="formLabelAlign.type"
          controls-position="right"
          :min="1"
        ></el-input-number>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="sure"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/adminERP/erp";
// import qs from "qs";
export default {
  name: "orderReport",
  data() {
    return {
      total: 0,
      pageNumber: 1,
      pageSize: 10,
      loading: false,
      dialogVisible: false,
      input: "",
      tableData: [],
      formLabelAlign: {
        name: "",
        type: "",
      },
      colorId: "",
    };
  },
  created() {
    this.inits();
  },
  methods: {
    sure() {
      this.loading = true;
      let data = {
        board: this.formLabelAlign.type,
      };
      api
        .editColors(this.colorId, data)
        .then((res) => {
          if (res.success) {
            this.$message.success("修改成功");
            this.dialogVisible = false;
            this.loading = false;
            this.inits();
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 关闭弹窗
    close() {
      this.formLabelAlign = {
        name: "",
        type: "",
      };
      this.dialogVisible = false;
    },
    // 编辑价格
    editPrice(item) {
      this.dialogVisible = true;
      this.colorId = item.id;
      let { name, board } = item;
      this.formLabelAlign = {
        name,
        type: board,
      };
    },
    // 查询
    query() {
      if (this.input === "") {
        this.$message.warning("请输入印色信息");
        return;
      }
      this.inits();
    },
    // 刷新
    refreshs() {
      this.input = "";
      this.inits();
    },
    // 获取报表数据
    inits() {
      console.log("筛选条件", this.input);
      let paramStr = this.input.replace(/\+/g, "%2B");
      api
        .getColors(this.pageNumber, this.pageSize, paramStr)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          } else {
            this.$message.err("获取数据失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.inits();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.inits();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .query {
    margin: 10px 0;
  }
}
.ipt {
  margin-bottom: 10px;
  ::v-deep .el-input-number {
    width: 100%;
  }
}
.page {
  margin: 10px 0;
}
</style>
