<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>首页维护</h3>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="网站基本信息维护" name="1">
          <div class="card-form">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="商城名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>

              <el-form-item label="热线电话">
                <el-input v-model="form.phone"></el-input>
              </el-form-item>

              <el-form-item label="网址">
                <el-input v-model="form.extendColumn5"></el-input>
              </el-form-item>

              <el-form-item label="备案号">
                <el-input v-model="form.extendColumn6"></el-input>
              </el-form-item>

              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="上传二维码">
                    <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-change="getFile1"
                        :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="上传logo">
                    <el-upload
                        class="avatar-uploader"
                        action="#"
                        :auto-upload="false"
                        :on-change="getFile2"
                        :show-file-list="false"
                        :before-upload="beforeAvatarUpload2"
                    >
                      <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>


              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="上传海报">
                    <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-change="getFile3"
                        :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="imageUrl3" :src="imageUrl3" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="小程序二维码">
                    <el-upload
                        class="avatar-uploader"
                        action="#"
                        :auto-upload="false"
                        :on-change="getFile4"
                        :show-file-list="false"
                        :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="imageUrl4" :src="imageUrl4" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <!-- <el-button>重置</el-button> -->
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="首页轮播图片维护" name="2">
          <div class="card-table">
            <div class="top">
              <el-button type="primary" @click="addBanner">新增</el-button>
            </div>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column
                label="图片"
                width="360"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-popover placement="top-start" trigger="click">
                    <a
                      :href="scope.row.extendColumn1"
                      target="_blank"
                      title="查看最大化图片"
                      ><img
                        :src="scope.row.extendColumn1"
                        style="width: 683px; height: 210px"
                    /></a>
                    <img
                      slot="reference"
                      class="imgs"
                      :src="scope.row.extendColumn1"
                      width="300"
                      height="100"
                      alt=""
                    />
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="名称"
                width="180"
                header-align="center"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="上传时间"
                width="180"
                header-align="center"
                align="center"
              >
              </el-table-column>
              <el-table-column
                label="操作"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <!-- <el-button type="primary" @click="editBanner(scope.row)"
                    >修改</el-button
                  > -->

                  <el-button type="danger" @click="delBanner(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="新增首页轮播图" :visible.sync="dialogVisible" width="30%">
      <el-form :model="dlgForm">
        <!-- <el-form-item label="排序" label-width="80px">
          <el-input v-model="dlgForm.name" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="上传图片" label-width="80px">
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="getFile"
            :before-upload="beforeAvatarUpload1"
          >
            <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sureAdd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/webAdmin/webAdmin";
export default {
  inject: ["reload"],
  name: "indexManage",
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      activeName: "1",
      imageUrl2: "", // logo图片
      imageUrl: "", // 二维码图片
      imageUrl1: "", // 首页图片
      imageUrl3:"",
      imageUrl4:"",
      dlgForm: {
        name: "",
      },
      form: {
        name: "",
        phone: "",
        qrCode: "",
        banner: "",
        extendColumn5:"",
        extendColumn6:""
      },
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      shopId: "", // 商城id
      file: {},
      logoFile: {},
      qrFile: {},
    applet:{},
    poster:{},
    };
  },
  created() {
    this.init();
    let obj = JSON.parse(window.sessionStorage.getItem("shoppingInfo"));
    // console.log("网页基本信息", obj);
    if (obj) {
      this.shopId = obj.id;
    }
    this.shopInfo();
  },
  methods: {
    addBanner() {
      if (this.tableData.length >= 5) {
        this.$message.warning("首页轮播图最多可设置 5 张!");
        this.dialogVisible = false;
      } else {
        this.dialogVisible = true;
      }
    },
    shopInfo() {
      api.getShop(this.shopId).then((res) => {
        console.log("店铺详情", res);
        if (res.success) {
          //this.$message.success("获取店铺信息成功");
          let obj = res.data;
          this.form.name = obj.extendColumn1;
          this.form.phone = obj.mallPhone;
          this.imageUrl2 = obj.extendColumn2; // logo
          this.imageUrl = obj.extendColumn3; // 二维码
          this.imageUrl3 = obj.posterPath; // 海报
          this.imageUrl4 = obj.appletsPath;// 小程序二维码
          this.form.extendColumn5 = obj.extendColumn5; // logo
          this.form.extendColumn6 = obj.extendColumn6; // 二维码
        }
      });
    },
    // 取消上传
    cancel() {
      this.imageUrl1 = "";
      this.dialogVisible = false;
      this.file = {};
    },
    // 确定新增banner
    sureAdd() {
      console.log(this.file);
      if (JSON.stringify(this.file) !== "{}") {
        let data = new FormData();
        data.append("multipartFile", this.file.raw);
        data.append("sort", "01");
        api
          .addbanners(data)
          .then((res) => {
            console.log("新增图片", res);
            if (res.success) {
              this.$message.success("新增图片成功");
              this.imageUrl1 = "";
              this.file = {};
              this.dialogVisible = false;
              this.init();
            } else {
              this.$message.error("删除失败");
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
      } else {
        this.$message.warning("请选择需要上传的图片");
      }
    },
    // 修改banner
    // editBanner(item) {
    //   console.log(item);
    // },
    // 删除banner
    delBanner(item) {
      console.log(item);
      api
        .delbanners(item.id)
        .then((res) => {
          console.log("删除图片", res);
          if (res.success) {
            this.$message.success("删除图片成功");
            this.init();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 获取banner列表
    init() {
      api
        .getbanners(this.pageNumber, this.pageSize)
        .then((res) => {
          // console.log("获取banner列表", res);
          if (res.success) {
            this.tableData = res.data.rows;
            this.total = res.data.total;
          } else {
            this.$message.error("查询失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 提交首页基本信息维护
    onSubmit() {
      let data = new FormData();
      data.append("extendColumn1", this.form.name);
      data.append("extendColumn5", this.form.extendColumn5);
      data.append("extendColumn6", this.form.extendColumn6);
      data.append("mallPhone", this.form.phone);
      data.append("logo", this.logoFile.raw);
      data.append("qrCode", this.qrFile.raw);
      data.append("poster", this.poster.raw);
      data.append("applets", this.applet.raw);
      api
        .editShop(this.shopId, data)
        .then((res) => {
          console.log("修改商城信息", res);
          if (res.success) {
            this.$router.go(0);
            this.init();
            this.$message.success("修改成功");
          } else {
            this.$message.error("修改失败");
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
    // 图片上传的钩子(轮播图)
    getFile(file) {
      console.log(file);
      let url = null;
      // 获取前台显示url
      if (window.createObjcectURL !== undefined) {
        url = window.createOjcectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      console.log("图片地址", url);
      this.imageUrl1 = url;
      this.file = file;
    },
    //二维码
    getFile1(file) {
      console.log(file);
      let url = null;
      // 获取前台显示url
      if (window.createObjcectURL !== undefined) {
        url = window.createOjcectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      console.log("图片地址", url);
      this.imageUrl = url;
      this.qrFile = file;
    },
    // logo
    getFile2(file) {
      console.log(file);
      let url = null;
      // 获取前台显示url
      if (window.createObjcectURL !== undefined) {
        url = window.createOjcectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      console.log("图片地址", url);
      this.imageUrl2 = url;
      this.logoFile = file;
    },
    getFile3(file) {
      console.log(file);
      let url = null;
      // 获取前台显示url
      if (window.createObjcectURL !== undefined) {
        url = window.createOjcectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      console.log("图片地址", url);
      this.imageUrl3 = url;
      this.poster = file;
    },
    getFile4(file) {
      console.log(file);
      let url = null;
      // 获取前台显示url
      if (window.createObjcectURL !== undefined) {
        url = window.createOjcectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      console.log("图片地址", url);
      this.imageUrl4 = url;
      this.applet = file;
    },
    //banner图
    beforeAvatarUpload1(file) {
      console.log(file);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 二维码
    // handleAvatarSuccess(res, file) {
    //   console.log(res, file);
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    //
    beforeAvatarUpload(file) {
      console.log(file);
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      // return isJPG && isLt2M;
      return isLt2M;
    },
    beforeAvatarUpload2(file) {
      console.log(file);
    },
  },
};
</script>
<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style lang="scss" scoped>
.card {
  width: 100%;
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .card-form {
    width: 60%;
    margin: 15px auto;
  }
  .card-table {
    padding: 0 20px;
    .top {
      margin-bottom: 10px;
    }
  }
}
</style>
