<template>
    <div>
        <div class="card">
            <div class="card-top">
                <span></span>
                <h3>公司信息维护</h3>
            </div>
            <el-tabs v-model="activeName">
                <div class="tab">

                    <el-form ref="ruleForm" :model="lxwm" label-width="100px">
                        <el-form-item label="客服QQ：">
                            <el-input v-model="lxwm.extendColumn1" placeholder="客服QQ1"></el-input>
                            <el-input v-model="lxwm.extendColumn2" placeholder="客服QQ2"></el-input>
                            <el-input v-model="lxwm.extendColumn3" placeholder="客服QQ3"></el-input>
                        </el-form-item>
                    </el-form>
                    <!--公司简介-->
                    <el-form ref="ruleForm" :model="form" label-width="100px" class="fwb">
                        <el-form-item label="公司简介：">
                            <editor
                                    ref="editorOne"
                                    v-model.trim="form.conmpanyProfileContext"
                                    @change="change"
                                    :info="form.conmpanyProfileContext"
                            ></editor>
                        </el-form-item>
                    </el-form>
                    <!--联系我们-->
                    <el-form ref="ruleForm" :model="lxwm" label-width="100px" class="fwb">
                        <el-form-item label="联系我们：">
                            <editor
                                    ref="editorOne"
                                    v-model.trim="lxwm.aboutUsContent"
                                    @change="change"
                                    :info="lxwm.aboutUsContent"
                            ></editor>
                        </el-form-item>
                    </el-form>
                    <!--转账信息-->
                    <el-form ref="ruleForm" :model="zzxx" label-width="100px" class="fwb">
                        <el-form-item label="转账信息：">
                            <editor
                                    ref="editorOne"
                                    v-model.trim="zzxx.mallBankInfo"
                                    @change="change"
                                    :info="zzxx.mallBankInfo"
                            ></editor>
                        </el-form-item>
                    </el-form>
                    <!--预约自提-->
                    <el-form ref="ruleForm" :model="yyzt" label-width="100px" class="fwb">
                        <el-form-item label="预约自提：">
                            <editor
                                    ref="editorOne"
                                    v-model.trim="yyzt.pickUpContext"
                                    @change="change"
                                    :info="yyzt.pickUpContext"
                            ></editor>
                        </el-form-item>
                    </el-form>
                    <!--送货上门-->
                    <el-form ref="ruleForm" :model="shsm" label-width="100px" class="fwb">
                        <el-form-item label="送货上门：">
                            <editor
                                    ref="editorOne"
                                    v-model.trim="shsm.provideHomeDeliveryContext"
                                    @change="change"
                                    :info="shsm.provideHomeDeliveryContext"
                            ></editor>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="cancle">取 消</el-button>
                        <el-button type="primary" @click="sureAdd">确 定</el-button>
                    </div>

                </div>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import api from "../../api/webAdmin/webAdmin";
    import Editor from "../../components/wangeditor/index";

    export default {
        name: "informationManage",
        components: {Editor},
        data() {
            return {
                activeName: '1',

                imageUrl1: "",
                //客服QQ
                QQ: {
                    qq1: '',
                    qq2: '',
                    qq3: ''
                },
                //公司简介
                form: {
                    conmpanyProfileContext: "", //公司简介
                },
                //联系我们
                lxwm: {
                    aboutUsContent: "",
                    extendColumn1: '',//QQ1
                    extendColumn2: '',//QQ2
                    extendColumn3: ''//QQ3
                },

                //转账信息
                zzxx: {
                    mallBankInfo: "",
                },

                //预约自提
                yyzt: {
                    pickUpContext: "",
                },

                //送货上门
                shsm: {
                    provideHomeDeliveryContext: "",
                },
                noticeId: "", //公司简介id
                lxwmId: "", //联系我们id
                zzxxId: "", //转账信息id
                yyztId: "", //预约自提id
                shsmId: "", //送货上门id

                //公司简介
                CompanyProfile: [],
            };
        },
        created() {
            this.init();
        },
        methods: {
            cancles() {
                // console.log("弹框关闭");
                this.clearFrom();
            },
            // 清空表单
            clearFrom() {
                // this.noticeId = "";
                // this.imageUrl1 = "";
                this.form = {
                    conmpanyProfileContext: this.form.conmpanyProfileContext, //公司简介
                };
                this.lxwm = {
                    aboutUsContent: this.lxwm.aboutUsContent,//联系我们
                    extendColumn1:this.lxwm.extendColumn1,//QQ1
                    extendColumn2:this.lxwm.extendColumn2,//QQ2
                    extendColumn3:this.lxwm.extendColumn3,//QQ3
                }
                this.zzxx = {
                    mallBankInfo: this.zzxx.mallBankInfo,//转账信息
                }
                this.yyzt = {
                    pickUpContext: this.yyzt.pickUpContext,//预约自提
                }
                this.shsm = {
                    provideHomeDeliveryContext: this.shsm.provideHomeDeliveryContext,//送货上门
                }
            },
            //取消
            cancle() {
                // this.dialogFormVisible = false;
                this.clearFrom();
            },
            // 新增
            sureAdd() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        console.log("验证成功");
                        this.gsjjadd()
                        this.lxwmadd()
                        this.zzxxadd()
                        this.yyztadd()
                        this.shsmadd()
                    } else {
                        this.$message.error("请认真填写带*的内容");
                        return false;
                    }
                });
            },
            //公司简介新增
            gsjjadd() {
                if (this.noticeId) {
                    console.log("修改");

                    // this.$http.put("/companyprofile/100000",obj.articleContent).then((res) => {
                    //     console.log("获取公司简介列表", res);
                    //     // if (res.data.code==200){
                    //     //     this.CompanyProfile=res.data.data
                    //     // }else{
                    //     //     this.$message.error("查询失败");
                    //     // }
                    // })

                    // this.$http.post("/companyprofile",data).then((res) => {
                    //     console.log("新增公司简介列表", res);
                    // })

                    api.modificationgsjj(this.noticeId, this.form).then((res) => {
                        console.log("修改是否成功", res);
                        if (res.success) {
                            this.clearFrom();
                            this.init();
                            // this.$message.success("修改成功");
                        } else {
                            this.$message.error("修改失败");
                        }
                    }).catch((error) => {
                        console.error("error", error);
                    });
                } else {
                    this.$http.post("/companyprofile", this.form).then((res) => {
                        console.log("新增公司简介列表", res);
                        if (res.data.code == 200) {
                            this.init();
                        } else {
                            this.$message.error("新增失败");
                        }
                    })
                }
            },
            //联系我们新增
            lxwmadd() {
                if (this.lxwmId) {
                    console.log("修改联系我们")
                    if (this.lxwm.extendColumn1==null&&this.lxwm.extendColumn2==null&&this.lxwm.extendColumn3==null){
                        this.$message({
                            message: '至少填写一个客服信息',
                            type: 'warning'
                        });
                    }else{
                        api.modificationlxwm(this.lxwmId, this.lxwm).then((res) => {
                            console.log("修改是否成功", res);
                            console.log(this.lxwm)
                            if (res.success) {
                                this.clearFrom();
                                this.init();
                                // this.$message.success("修改成功");
                            } else {
                                this.$message.error("修改失败");
                            }
                        }).catch((error) => {
                            console.error("error", error);
                        });
                    }
                } else {
                    if (this.lxwm.extendColumn1==null&&this.lxwm.extendColumn2==null&&this.lxwm.extendColumn3==null){
                        this.$message({
                            message: '至少填写一个客服信息',
                            type: 'warning'
                        });
                    }else {
                        this.$http.post("/mallaboutus", this.lxwm).then((res) => {
                            console.log("新增联系我们列表", res);
                            if (res.data.code == 200) {
                                this.init();
                            } else {
                                this.$message.error("新增失败");
                            }
                        })
                    }
                }
            },
            //转账信息新增
            zzxxadd() {
                if (this.zzxxId) {
                    console.log("修改转账信息")
                    api.modificationzzxx(this.zzxxId, this.zzxx).then((res) => {
                        console.log("修改是否成功", res);
                        if (res.success) {
                            this.clearFrom();
                            this.init();
                            // this.$message.success("修改成功");
                        } else {
                            this.$message.error("修改失败");
                        }
                    }).catch((error) => {
                        console.error("error", error);
                    });
                } else {
                    this.$http.post("/bankaccounttransfer", this.zzxx).then((res) => {
                        console.log("新增转账信息列表", res);
                        if (res.data.code == 200) {
                            this.init();
                        } else {
                            this.$message.error("新增失败");
                        }
                    })
                }
            },
            //预约自提新增
            yyztadd() {
                if (this.yyztId) {
                    console.log("修改预约自提")
                    api.modificationyyzt(this.yyztId, this.yyzt).then((res) => {
                        console.log("修改是否成功", res);
                        console.log(this.yyzt)
                        if (res.success) {
                            this.clearFrom();
                            this.init();
                            // this.$message.success("修改成功");
                        } else {
                            this.$message.error("修改失败");
                        }
                    }).catch((error) => {
                        console.error("error", error);
                    });
                } else {
                    this.$http.post("/appointmentpickup", this.yyzt).then((res) => {
                        console.log("新增预约自提列表", res);
                        if (res.data.code == 200) {
                            this.init();
                        } else {
                            this.$message.error("新增失败");
                        }
                    })
                }
            },
            //送货上门新增
            shsmadd() {
                if (this.shsmId) {
                    console.log("修改送货上门")
                    api.modificationshsm(this.shsmId, this.shsm).then((res) => {
                        console.log("修改是否成功", res);
                        console.log(this.shsm)
                        if (res.success) {
                            this.clearFrom();
                            this.init();
                            // this.$message.success("修改成功");
                        } else {
                            this.$message.error("修改失败");
                        }
                    }).catch((error) => {
                        console.error("error", error);
                    });
                } else {
                    this.$http.post("/providehomedelivery", this.shsm).then((res) => {
                        console.log("新增送货上门列表", res);
                        if (res.data.code == 200) {
                            this.init();
                        } else {
                            this.$message.error("新增失败");
                        }
                    })
                }
            },

            //富文本内容变化
            change(val) {
                console.log(val);
            },

            //获取公司简介列表
            init() {
                this.$http.get("/companyprofile/listAll").then((res) => {
                    console.log("获取公司简介列表", res);
                    if (res.data.code == 200) {
                        this.CompanyProfile = res.data.data
                        this.noticeId = res.data.data[0].id
                        this.form.conmpanyProfileContext = res.data.data[0].conmpanyProfileContext
                    } else {
                        this.$message.error("查询失败");
                    }
                })

                this.$http.get("/mallaboutus/listAll").then((res) => {
                    console.log("获取联系我们列表", res);
                    if (res.data.code == 200) {
                        this.lxwmId = res.data.data[0].id
                        this.lxwm.aboutUsContent = res.data.data[0].aboutUsContent
                        this.lxwm.extendColumn1 = res.data.data[0].extendColumn1
                        this.lxwm.extendColumn2 = res.data.data[0].extendColumn2
                        this.lxwm.extendColumn3 = res.data.data[0].extendColumn3
                    } else {
                        this.$message.error("查询失败");
                    }
                })

                this.$http.get("/bankaccounttransfer/listAll").then((res) => {
                    console.log("获取转账信息列表", res);
                    if (res.data.code == 200) {
                        this.zzxxId = res.data.data[0].id
                        this.zzxx.mallBankInfo = res.data.data[0].mallBankInfo
                    } else {
                        this.$message.error("查询失败");
                    }
                })

                this.$http.get("/appointmentpickup/listAll").then((res) => {
                    console.log("获取预约自提列表", res);
                    if (res.data.code == 200) {
                        this.yyztId = res.data.data[0].id
                        this.yyzt.pickUpContext = res.data.data[0].pickUpContext
                    } else {
                        this.$message.error("查询失败");
                    }
                })

                this.$http.get("/providehomedelivery/listAll").then((res) => {
                    console.log("获取送货上门列表", res);
                    if (res.data.code == 200) {
                        this.shsmId = res.data.data[0].id
                        this.shsm.provideHomeDeliveryContext = res.data.data[0].provideHomeDeliveryContext
                    } else {
                        this.$message.error("查询失败");
                    }
                })

            },
        },
    };
</script>
<style lang="scss">
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    /*富文本高度*/
    .w-e-text-container {
        height: 140px !important;
    }

    .w-e-toolbar .w-e-menu {
        width: 30px!important;
        height: 30px!important;
    }

    .fwb{
        .el-form-item__content>div{
            width: 100% !important;
        }
    }

    .el-form-item__content{
        /*#wangEditor-1,#wangEditor-2,#wangEditor-3{*/
        /*    width: 100% !important;*/
        /*}*/
        #wangEditor-2{
            width: 100% !important;
        }
        #wangEditor-3{
            width: 100% !important;
        }
    }


    /*客服QQ*/
    .el-form-item__content {
        display: flex;
        /*justify-content: space-between;*/
        .el-input {
            width: auto;
            /*margin-right: 24px;*/
        }
    }
</style>
<style lang="scss" scoped>
    .card {
        width: 100%;

        .card-top {
            display: flex;
            align-items: center;
            height: 50px;
            border-bottom: 1px solid #e6e6e6;
            padding: 0 30px;
            line-height: 50px;

            span {
                display: inline-block;
                width: 5px;
                height: 17px;
                background: #058af2;
                margin-right: 20px;
            }

            h3 {
                color: #058af2;
            }
        }

        .tab {
            padding: 10px;

            .tap {
                margin-bottom: 10px;
            }
        }

        .page {
            margin-top: 20px;
        }
    }
</style>
