<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>公告管理列表</h3>
      </div>
      <div class="tab">
        <div class="tap">
          <el-button
            type="primary"
            class="btn"
            @click="dialogFormVisible = true"
            >新增</el-button
          >
        </div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            label="图片"
            width="180"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-popover placement="top-start" trigger="click">
                <a
                  :href="scope.row.extendColumn1"
                  target="_blank"
                  title="查看最大化图片"
                  ><img
                    :src="scope.row.extendColumn1"
                    style="width: 372px; height: 210px"
                /></a>
                <img
                  slot="reference"
                  class="imgs"
                  :src="scope.row.extendColumn1"
                  width="180"
                  height="100"
                  alt=""
                />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="articleTitle"
            label="标题"
            width="180"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="publishername"
            label="发布人"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="200"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-button @click="editNotice(scope.row)" type="warning"
                >修改</el-button
              >
              <el-button type="danger" @click="delNodice(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.currentPage"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="公告编辑"
      :visible.sync="dialogFormVisible"
      @close="cancles"
    >
      <el-form ref="ruleForm" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="类型" prop="articleType">
          <el-select v-model="form.articleType">
            <el-option label="文章" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="articleTitle">
          <el-input v-model="form.articleTitle"></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="articleLable">
          <el-input v-model="form.articleLable"></el-input>
        </el-form-item>
        <el-form-item label="发布人" prop="publishername">
          <el-input v-model="form.publishername"></el-input>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="推荐" prop="recommend">
              <el-switch
                v-model="form.recommend"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
              {{ form.recommend === "0" ? "否" : "是" }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="articleState">
              <el-switch
                v-model="form.articleState"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
              {{ form.articleState === "0" ? "草稿" : "发布" }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="结束时间" prop="endtime">
          <el-date-picker
            v-model="form.endtime"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="上传图片" prop="multipartFile">
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="getFile"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容编辑" prop="articleContent">
          <editor
            ref="editorOne"
            v-model.trim="form.articleContent"
            @change="change"
            :info="form.articleContent"
          ></editor>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button type="primary" @click="sureAdd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/webAdmin/webAdmin";
import Editor from "../../components/wangeditor/index";
export default {
  name: "noticeManage",
  components: { Editor },
  data() {
    return {
      detail: "",
      imageUrl1: "",
      dialogFormVisible: false,
      form: {
        articleType: "0", // 类型 文章-0
        articleTitle: "", //标题
        articleLable: "", // 标签
        articleContent: "", //内容
        recommend: "0", //推荐（0 否，1是）
        endtime: "", //结束时间
        articleState: "0", //状态（0 草稿，1发布）
        publisher: "", //发布人
        publishername: "", //发布人姓名
        multipartFile: "", //图片
      },
      rules: {
        articleType: [
          { required: true, message: "请选择类型", trigger: "blur" },
        ],
        articleTitle: [
          { required: true, message: "请输入标题", trigger: "change" },
        ],
        // articleLable: [
        //   { required: true, message: "请输入标签", trigger: "change" },
        // ],
        publishername: [
          { required: true, message: "请输入发布人", trigger: "blur" },
        ],
        recommend: [
          { required: true, message: "请选择是否推荐", trigger: "blur" },
        ],
        articleState: [
          { required: true, message: "请选择当前状态", trigger: "blur" },
        ],
        // endtime: [
        //   {
        //     type: "date",
        //     required: true,
        //     message: "请选择日期",
        //     trigger: "change",
        //   },
        // ],

        // multipartFile: [
        //   { required: true, message: "请选择图片", trigger: "blur" },
        // ],
        articleContent: [
          { required: true, message: "请填写内容", trigger: "blur" },
        ],
      },
      pages: {
        pageSize: 5,
        currentPage: 1,
      },
      total: 0,
      tableData: [],
      noticeId: "", //文章id
    };
  },
  created() {
    this.init();
  },
  methods: {
    // getImgToBase64(url, callback) {
    //   //将图片转换为Base64
    //   var canvas = document.createElement("canvas"),
    //     ctx = canvas.getContext("2d"),
    //     img = new Image();
    //   img.crossOrigin = "Anonymous";
    //   img.onload = function () {
    //     canvas.height = img.height;
    //     canvas.width = img.width;
    //     ctx.drawImage(img, 0, 0);
    //     var dataURL = canvas.toDataURL("image/png");
    //     callback(dataURL);
    //     canvas = null;
    //   };
    //   img.src = url;
    // },
    // dataURLtoFile(dataurl, filename) {
    //   //将base64转换为文件
    //   var arr = dataurl.split(","),
    //     mime = arr[0].match(/:(.*?);/)[1],
    //     bstr = atob(arr[1]),
    //     n = bstr.length,
    //     u8arr = new Uint8Array(n);
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
    //   return new File([u8arr], filename, { type: mime });
    // },
    // 对话框关闭
    cancles() {
      // console.log("弹框关闭");
      this.clearFrom();
    },
    // 清空表单
    clearFrom() {
      this.noticeId = "";
      this.imageUrl1 = "";
      this.form = {
        articleType: "0", // 类型 文章-0
        articleTitle: "", //标题
        articleLable: "", // 标签
        articleContent: "", //内容
        recommend: "0", //推荐（0 否，1是）
        endtime: "", //结束时间
        articleState: "0", //状态（0 草稿，1发布）
        publisher: "", //发布人
        publishername: "", //发布人姓名
        multipartFile: "", //图片
      };
    },
    //取消
    cancle() {
      this.dialogFormVisible = false;
      this.clearFrom();
    },
    // 新增
    sureAdd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log("验证成功");
          let data = new FormData();
          let obj = this.form;
          data.append("articleType", obj.articleType);
          data.append("articleTitle", obj.articleTitle);
          data.append("articleLable", obj.articleLable);
          data.append("articleContent", obj.articleContent);
          data.append("recommend", obj.recommend);
          data.append("endtime", obj.endtime);
          data.append("publisher", obj.publisher);
          data.append("articleState", obj.articleState);
          data.append("publishername", obj.publishername);
          data.append("multipartFile", obj.multipartFile);
          if (this.noticeId) {
            // this.getImgToBase64(this.imageUrl1, function (data) {
            //   var myFile = this.dataURLtoFile(data, "testimgtestimgtestimg");
            //   console.log(myFile);
            // });
            console.log("是修改啊");
            api
              .editNotice(this.noticeId, data)
              .then((res) => {
                console.log("修改是否成功", res);
                if (res.success) {
                  this.clearFrom();
                  this.dialogFormVisible = false;
                  this.init();
                  this.$message.success("修改成功");
                } else {
                  this.$message.error("修改失败");
                }
              })
              .catch((error) => {
                console.error("error", error);
              });
          } else {
            api
              .addNotice(data)
              .then((res) => {
                console.log("新增文章", res);
                if (res.success) {
                  this.clearFrom();
                  this.dialogFormVisible = false;
                  this.init();
                } else {
                  this.$message.error("新增失败");
                }
              })
              .catch((error) => {
                console.error("error", error);
              });
          }
        } else {
          this.$message.error("请认真填写带*的内容");
          return false;
        }
      });
    },
    //富文本内容变化
    change(val) {
      console.log(val);
    },
    // 图片上传的钩子
    getFile(file) {
      console.log(file);
      let url = null;
      // 获取前台显示url
      if (window.createObjcectURL !== undefined) {
        url = window.createOjcectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      console.log("图片地址", url);
      this.imageUrl1 = url;
      this.form.multipartFile = file.raw;
    },
    beforeAvatarUpload() {},
    //获取公告列表
    init() {
      api
        .getArticle(this.pages.currentPage, this.pages.pageSize)
        .then((res) => {
          console.log("获取公告列表", res);
          if (res.success) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          } else {
            this.$message.error("查询失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 删除文章
    delNodice(item) {
      console.log(item);

      console.log(11);
      this.$confirm("此操作将永久删除该文章, 是否继续?", "删除操作", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .delNotice(item.id)
            .then((res) => {
              if (res.success) {
                this.init();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message.error("删除失败");
              }
            })
            .catch((error) => {
              console.error("error", error);
            });
        })
        .catch(() => {});
    },
    // 编辑文章
    editNotice(item) {
      console.log(item);
      this.noticeId = item.id;
      this.form = {
        articleType: item.articleType.toString(), // 类型 文章-0
        articleTitle: item.articleTitle, //标题
        articleLable: item.articleLable, // 标签
        articleContent: item.articleContent, //内容
        recommend: item.recommend.toString(), //推荐（0 否，1是）
        endtime: item.endtime, //结束时间
        articleState: item.articleState.toString(), //状态（0 草稿，1发布）
        publisher: "", //发布人
        publishername: item.publishername, //发布人姓名
        multipartFile: "",
      };
      // this.$nextTick(() => {
      //   this.$refs.editorOne.getHtml(item.articleContent);
      // });
      this.imageUrl1 = item.extendColumn1;
      this.dialogFormVisible = true;
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.init();
    },
  },
};
</script>
<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style lang="scss" scoped>
.card {
  width: 100%;
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .tab {
    padding: 10px;
    .tap {
      margin-bottom: 10px;
    }
  }
  .page {
    margin-top: 20px;
  }
}
</style>
