import request from "@/api/request";
// const basUrl = "http://cpsapi.yy12365.com";
// const localUrl = "http://192.168.0.113";

// 钱包明细
function walletInfo(pageNumber, pageSize, type, extendColumn2, userId, startTime, endTime, data) {
    return request({
        method: "get",
        url: `/usewallet?pageNumber=${pageNumber}&pageSize=${pageSize}&type=${type}&extendColumn2=${extendColumn2}&userId=${userId}&startTime=${startTime}&endTime=${endTime}`,
        data,
    });
}

// 查询用户列表
function queryUser(pageNumber, pageSize, userLevel, userName, custName, userType, data) {
    return request({
        method: "get",
        url: `/user/allCustomerUser?pageNumber=${pageNumber}&pageSize=${pageSize}&userLevel=${userLevel}&USERNAME=${userName}&custName=${custName}&userType=${userType}`,
        data,
    });
}

export default {
    walletInfo,
    queryUser
};
