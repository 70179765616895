import request from "@/api/request";

// 获取材料列表
function getLists(pageNumber, pageSize, condition, data) {
    return request({
        method: "get",
        url: `/material?pageNumber=${pageNumber}&pageSize=${pageSize}&condition=${condition}`,
        data,
    });
}

// 获取克重列表
function getWeights(pageNumber, pageSize, id, condition, data) {
    return request({
        method: "get",
        url: `/weight?pageNumber=${pageNumber}&pageSize=${pageSize}&materialId=${id}&condition=${condition}`,
        data,
    });
}

// 修改克重
function editWeights(id, data) {
    return request({
        method: "put",
        url: `/weight/${id}`,
        data,
    });
}

// 获取印色列表
function getColors(pageNumber, pageSize, condition, data) {
    return request({
        method: "get",
        url: `/colour?pageNumber=${pageNumber}&pageSize=${pageSize}&condition=${condition}`,
        data,
    });
}

// 修改印色价格
function editColors(id, data) {
    return request({
        method: "put",
        url: `/colour/${id}`,
        data,
    });
}

// 保存&编辑_印刷报价参数配置
function editPrintParamData(data) {
    return request({
        method: "post",
        url: `/cpshttp/editPrintParamData`,
        data,
    });
}

// 回显数据_印刷报价参数配置
function getPrintParamData(userfatherid, data) {
    return request({
        method: "get",
        url: `/cpshttp/getPrintParamData?userfatherid=${userfatherid}`,
        data,
    });
}

export default {
    getColors,
    editColors,
    getLists,
    getWeights,
    editWeights,

    editPrintParamData,
    getPrintParamData
};
