<template>
  <div class="info">
    <div class="card-top">
      <span></span>
      <h3>投诉管理</h3>
    </div>
    <!-- 查询条件 -->
    <div class="query">
      <el-input
        class="input"
        v-model="querys"
        placeholder="请输入订单号"
      ></el-input>
      <el-select v-model="value2" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button class="btn" type="primary" @click="getcomplaint"
        >查询</el-button
      >
      <el-button @click="refreshs">刷新</el-button>
    </div>
    <!--表格-->
    <div class="card-form table">
      <el-table :data="getcomplaintList" border style="width: 100%">
        <el-table-column header-align="center" label="投诉编号">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="订单编号" width="200">
          <template slot-scope="scope">
            {{ scope.row.orderNumber }}
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="投诉内容" align="center">
          <template slot-scope="scope">
            {{ scope.row.detailstext }}
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="投诉状态">
          <template slot-scope="scope">
            {{ scope.row.extendColumn1 === "0" ? "待处理" : "已处理" }}
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="发起时间" width="200">
          <template slot-scope="scope">
            {{ scope.row.updateTime }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          label="操作"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-link
              @click="details(scope.row)"
              :underline="false"
              type="primary"
              >查看详情</el-link
            >
            <el-link
              style="margin-left: 10px"
              @click="orderDetails(scope.row)"
              :underline="false"
              type="primary"
              >订单详情</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 订单详情弹框 -->
    <el-dialog title="订单详情" :visible.sync="dialogVisible" width="60%">
      <simple-details :key="timer" :orderId="orderId"></simple-details>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 投诉单详情 -->
    <el-dialog
      :visible.sync="complaints"
      class="detail"
      title="投诉单详情"
      width="55%"
    >
      <div class="detail_top">
        <!--            <img :src="picUrls+complaintsdetail.productimage" alt=""/>-->
        <div>
          <p>投诉日期：{{ complaintsdetail.updateTime }}</p>
        </div>
        <div>*{{ complaintsdetail.orderNumber }}*</div>
      </div>
      <div class="hand">
        <h2>客户投诉处理单(ORDER)</h2>
        <div class="hand_line">
          <div class="title">客户名称</div>
          <span>:</span>
          <div class="text">{{ complaintInfo.customername }}</div>
        </div>
        <div class="hand_line">
          <div class="title">联系方式</div>
          <span>:</span>
          <div class="text">{{ complaintInfo.contacttel }}</div>
        </div>
        <div class="hand_line">
          <div class="title">印件名称</div>
          <span>:</span>
          <div class="text">{{ complaintInfo.ordername }}</div>
        </div>
        <div class="hand_line">
          <div class="title">订单编号</div>
          <span>:</span>
          <div class="text">{{ complaintsdetail.id }}</div>
        </div>
        <div class="hand_line">
          <div class="title">产品数量</div>
          <span>:</span>
          <div class="text">{{ complaintInfo.quantitydemanded }}</div>
        </div>
        <div class="hand_line">
          <div class="title">订单金额</div>
          <span>:</span>
          <div class="text">{{ complaintInfo.discountfee }}</div>
        </div>
        <div class="hand_line">
          <div class="title">投诉分类</div>
          <span>:</span>
          <div class="text">
            {{ complaintsType }}
          </div>
        </div>
        <div class="cont">
          <div class="title">投诉内容:</div>
          <div>
            <div
              class="content"
              v-html="complaintsdetail.complaintsContext"
            ></div>
          </div>
        </div>
        <div class="bom">
          <div class="bom-left">
            <div class="titles">处理结果:</div>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model.trim="textarea"
            >
            </el-input>
          </div>
          <div class="bom-right">
            <div class="date">
              <span>处理时间：</span>
              <el-date-picker
                v-model="value1"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="bottom">
          <el-button @click="cancel">取消</el-button>
          <el-button
            type="primary"
            @click="sure"
            :disabled="complaintsdetail.extendColumn1 === '1'"
            >{{
              complaintsdetail.extendColumn1 === "1" ? "已处理" : "处理"
            }}</el-button
          >
        </div>
      </div>

      <!--          &lt;!&ndash;投诉分类&ndash;&gt;-->
      <!--          <el-form ref="form" :rules="rule" :model="form" label-width="100px" class="fwb">-->
      <!--            <el-form-item label="投诉分类：" prop="types">-->
      <!--              <el-checkbox-group v-model="form.types" @change="CheckedChange">-->
      <!--                <el-checkbox v-for="item in ComplaintClassification" :label="item"-->
      <!--                             :value="item.id" :key="item.id">{{item.name}}-->
      <!--                </el-checkbox>-->
      <!--              </el-checkbox-group>-->
      <!--            </el-form-item>-->
      <!--            &lt;!&ndash;投诉内容&ndash;&gt;-->
      <!--            &lt;!&ndash;                <el-form ref="rule" :model="form" label-width="100px" class="fwb">&ndash;&gt;-->
      <!--            <el-form-item label="投诉内容：" prop="text">-->
      <!--              <editor-->
      <!--                      v-model.trim="form.text"-->
      <!--                      @change="change"-->
      <!--                      :info="form.text"-->
      <!--              ></editor>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item>-->
      <!--              <el-button type="primary" @click="submitForm('form')">立即投诉</el-button>-->
      <!--              <el-button @click="resetForm('form')">重置</el-button>-->
      <!--            </el-form-item>-->

      <!--          </el-form>-->
    </el-dialog>

    <!--分页-->
    <div class="pages">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 10, 20, 30]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import picUrl from "../../api/picture";
import api from "../../api/webAdmin/webAdmin";
import simpleDetails from "../../components/OrderDetails/simpleDetails.vue";
export default {
  name: "complaintManage",
  components: { simpleDetails },
  data() {
    return {
      dialogVisible: false,
      orderId: "",
      textarea: "已协商", // 处理结果
      complaintInfo: {},
      value2: "",
      options: [
        {
          label: "未处理",
          value: "0",
        },
        {
          label: "已处理",
          value: "1",
        },
      ],
      value1: "", // 投诉处理时间
      querys: "", // 订单号查询
      checked: 0,
      total: 0,
      currentPage: 1,
      pageSize: 5,
      Info: [
        {
          id: 0,
          text: "投诉评价",
        },
      ],
      //获取投诉列表
      getcomplaintList: [],
      complaints: false, // 投诉详情弹窗打开
      complaintsdetail: {},
      picUrls: "",
      complaintId: "",

      complaintsType: "", //投诉详情_投诉分类
      timer: "",
    };
  },
  created() {
    this.picUrls = picUrl.picUrl;
    this.getcomplaint();
  },
  methods: {
    // 订单详情
    orderDetails(item) {
      this.timer = new Date().getTime();
      console.log(item);
      this.orderId = item.orderNumber;
      this.dialogVisible = true;
    },
    //   取消
    cancel() {
      this.complaints = false;
      this.value1 = this.textarea = "";
    },
    // 确定
    sure() {
      if (this.value1 === "" || this.textarea === "") {
        this.$message.warning("请认真填写处理时间和处理结果！");
        return;
      }
      let data = new FormData();
      data.append("extendColumn1", "1");
      data.append("extendColumn2", this.textarea);
      data.append("extendColumn3", this.value1);
      api
        .complaintEdit(this.complaintId, data)
        .then((res) => {
          console.log("处理投诉", res);
          if (res.success) {
            this.$message.success("处理成功");
            this.complaints = false;
            this.getcomplaint();
          } else {
            this.$message.success("处理失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 刷新
    refreshs() {
      this.value2 = this.querys = "";
      this.getcomplaint();
    },
    //获取投诉
    getcomplaint() {
      this.$http
        .get(
          `/ordercomplaints?pageSize=${this.pageSize}&pageNumber=${this.currentPage}&extendColumn1=${this.value2}&orderNumber=${this.querys}`
        )
        .then((res) => {
          console.log("获取投诉列表", res);
          if (res.data.code == 200) {
            // this.$message({
            //   message: "投诉成功",
            //   type: "success",
            // });
            this.total = res.data.data.total;
            this.getcomplaintList = res.data.data.list;

            for (let a = 0; a < this.getcomplaintList.length; a++) {
              this.getcomplaintList[a].list = [];
              this.getcomplaintList[a].details = [];
              this.getcomplaintList[a].detailstext = "";
              this.getcomplaintList[a].list = this.getcomplaintList[
                a
              ].complaintsType.split(",");
              for (let c = 0; c < this.getcomplaintList[a].list.length; c++) {
                if (this.getcomplaintList[a].list[c] == 0) {
                  this.getcomplaintList[a].details.push("质量");
                } else if (this.getcomplaintList[a].list[c] == 1) {
                  this.getcomplaintList[a].details.push("数量");
                } else if (this.getcomplaintList[a].list[c] == 2) {
                  this.getcomplaintList[a].details.push("交期");
                } else if (this.getcomplaintList[a].list[c] == 3) {
                  this.getcomplaintList[a].details.push("物流");
                } else if (this.getcomplaintList[a].list[c] == 4) {
                  this.getcomplaintList[a].details.push("包装");
                } else if (this.getcomplaintList[a].list[c] == 5) {
                  this.getcomplaintList[a].details.push("服务");
                } else if (this.getcomplaintList[a].list[c] == 6) {
                  this.getcomplaintList[a].details.push("印前");
                } else if (this.getcomplaintList[a].list[c] == 7) {
                  this.getcomplaintList[a].details.push("其他");
                }
              }
              for (
                let b = 0;
                b < this.getcomplaintList[a].details.length;
                b++
              ) {
                this.getcomplaintList[a].detailstext =
                  this.getcomplaintList[a].detailstext +
                  this.getcomplaintList[a].details[b] +
                  "，";
              }
              // 去掉最后一个逗号
              if (this.getcomplaintList[a].detailstext.length != 0) {
                this.getcomplaintList[a].detailstext = this.getcomplaintList[
                  a
                ].detailstext.substring(
                  0,
                  this.getcomplaintList[a].detailstext.length - 1
                );
              }
            }

            console.log(this.getcomplaintList);
          } else {
            this.$message.error("查询失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },

    //投诉详情
    details(item) {
      console.log(item);
      this.complaints = true;
      this.complaintsdetail = item;
      this.complaintId = item.id;

      let array = item.complaintsType.split(",");
      console.log(array);
      let list = [];
      this.complaintsType = "";
      for (let a = 0; a < array.length; a++) {
        if (array[a] == 0) {
          list.push("质量");
        } else if (array[a] == 1) {
          list.push("数量");
        } else if (array[a] == 2) {
          list.push("交期");
        } else if (array[a] == 3) {
          list.push("物流");
        } else if (array[a] == 4) {
          list.push("包装");
        } else if (array[a] == 5) {
          list.push("服务");
        } else if (array[a] == 6) {
          list.push("印前");
        } else if (array[a] == 7) {
          list.push("其他");
        }
      }

      for (let a = 0; a < list.length; a++) {
        this.complaintsType = this.complaintsType + list[a] + "，";
      }
      console.log(this.complaintsType);
      // 去掉最后一个逗号
      if (this.complaintsType.length != 0) {
        this.complaintsType = this.complaintsType.substring(
          0,
          this.complaintsType.length - 1
        );
      }

      api
        .complaintInfo(item.id)
        .then((res) => {
          console.log("获取投诉详情", res);
          if (res.success) {
            this.complaintInfo = res.data.orderInfo;
          } else {
            this.$message.error("获取详情失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      console.log(this.pageSize);
      this.getcomplaint();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getcomplaint();
      console.log(this.currentPage);
      this.getcomplaint();
    },
  },
};
</script>

<style lang="scss" scoped>
.bom {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  .bom-left {
    display: flex;
    .titles {
      width: 80px;
      text-align: justify;
      text-align-last: justify;
      font-weight: bold;
      color: black;
    }
  }
}
.cont {
  .title {
    width: 80px;
    text-align: justify;
    text-align-last: justify;
    font-weight: bold;
    color: black;
  }
}
.card-top {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #e6e6e6;
  padding: 0 30px;
  line-height: 50px;
  span {
    display: inline-block;
    width: 5px;
    height: 17px;
    background: #058af2;
    margin-right: 20px;
  }
  h3 {
    color: #058af2;
  }
}
/* 查询条件 */
.query {
  margin: 10px 0;
  display: flex;
  align-items: center;
  .input {
    width: 30%;
    margin: 0 10px;
  }
  .btn {
    margin-left: 10px;
  }
}
/*表格*/
.table {
  margin-top: 10px;
  margin-right: 0;
  border-top: 1px dashed #e6e6e6;
}

/*分页*/
.pages {
  display: flex;
  margin: 20px 20px;
  justify-content: space-between;
}

/*订单详情*/
.detail {
  .detail_top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 30px;
      height: 30px;
      border: 1px solid #f7f7f7;
    }

    p {
      text-align: left;
    }
  }

  .hand {
    h2 {
      margin: 15px 0;
      text-align: center;
    }

    .hand_line {
      display: inline-flex;
      width: 50%;
      line-height: 30px;

      .title,
      span {
        font-weight: bold;
        color: black;
      }

      .title {
        width: 80px;
        text-align: justify;
        text-align-last: justify;
      }

      .text {
        border-bottom: 1px solid #eee;
        margin-left: 20px;
        color: #666;
        width: calc(100% - 80px);
        text-align: left;
        line-height: 30px;
      }
    }

    h4 {
      color: red;
      text-align: center;
      margin: 10px 0;
    }
  }
  .bottom {
    margin: 10px;
    float: right;
  }
}
</style>

<style lang="scss">
.detail {
  .el-dialog {
    /*height: 75%;*/
    height: auto !important;
    overflow: auto;

    .el-dialog__header {
      padding: 20px 20px 20px;
      // position: fixed;
      background: #fff;
      width: calc(55% - 40px);
    }

    // .el-dialog__body {
    //   margin-top: 64px;
    // }
  }
}
</style>
