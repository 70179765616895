<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>待收款订单管理</h3>
      </div>
      <!-- 筛选条件 -->
      <div class="screen">
        <el-row :gutter="10">
          <el-col :span="14" class="colls">
            <span>按日期查询:</span>
            <el-date-picker
              v-model="startTime"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <div class="kuoz">-</div>
            <el-date-picker
              v-model="endtime"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="9" style="line-height: 40px; margin-top: 15px">
            <el-form style="display: flex">
              <el-form-item>
                <el-input
                  v-model="cpid"
                  placeholder="请输入产品名称或ID查询"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button class="btn" type="primary" @click="query"
                  >查询</el-button
                >
                <el-button class="btn" @click="refresh">刷新</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="10">
          <el-col :span="24" class="coll">

          </el-col>
        </el-row> -->
      </div>
      <div class="tables">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column label="订单详情" width="500" header-align="center">
            <template slot-scope="scope">
              <div class="top">
                下单时间：<span>{{ scope.row.ordertime }}</span> 订单编号：<span
                  >{{ scope.row.id }}</span
                >
              </div>
              <div class="box">
                <div class="img">
                  <img :src="picUrls + scope.row.productimage" alt="" />
                </div>
                <div class="info">
                  <p class="p1">产品名称:{{ scope.row.ordername }}</p>
                  <p class="p3">
                    产品类型:<span>{{
                      scope.row.productid == 1038
                        ? "综合"
                        : scope.row.productid == 1036
                        ? "挂历"
                        : scope.row.productid == 1035
                        ? "台历"
                        : scope.row.productid == 1034
                        ? "精装书"
                        : scope.row.productid == 1032
                        ? "封套"
                        : scope.row.productid == 1028
                        ? "单页/海报"
                        : scope.row.productid == 1027
                        ? "教辅"
                        : scope.row.productid == 1024
                        ? "瓦楞彩箱"
                        : scope.row.productid == 1023
                        ? "单层卡纸彩盒"
                        : scope.row.productid == 1020
                        ? "画册/样本"
                        : scope.row.productid == 1018
                        ? "装帧加工"
                        : scope.row.productid == 1016
                        ? "印刷加工"
                        : scope.row.productid == 1015
                        ? "手提袋"
                        : ""
                    }}</span>
                  </p>
                  <p class="p3">
                    印刷数量：<span>{{ scope.row.quantitydemanded }}</span>
                  </p>
                  <p class="p3">
                    订单金额：<span>{{ scope.row.discountfee }}</span>
                  </p>
                </div>
                <div class="userInfo">
                  <p>
                    用户名称:
                    <span class="span1">{{ scope.row.customername }}</span>
                  </p>
                  <p>
                    联系电话:
                    <span class="span2">{{ scope.row.contacttel }}</span>
                  </p>
                  <p class="p3" v-if="scope.row.whetherFullPayment !== null">
                    付款方式：<span v-if="scope.row.payStruts === 5">{{
                      scope.row.whetherFullPayment === 0 ? "全款" : "30% 预付款"
                    }}</span>
                    <span v-else style="color: #ff0000">---</span>
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单状态" width="300" header-align="center">
            <template slot-scope="scope">
              <div class="top">
                生产状态：<span>{{ typeFunc(scope.row.status) }}</span>
                支付状态：<span>{{
                  scope.row.payStruts === 3
                    ? "待支付"
                    : scope.row.payStruts === 4
                    ? "已支付(预付款)"
                    : scope.row.payStruts === 5
                    ? "已支付"
                    : ""
                }}</span>
              </div>
              <div class="box types">
                <!-- <p>待上传印刷文件</p> -->
                <!-- <el-button
                  @click="lookFile(scope.row)"
                  type="primary"
                  :disabled="scope.row.uploadEntry <= 0"
                  >{{ scope.row.uploadEntry > 0 ? "查看文件" : "未上传文件" }}
                </el-button> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" fixed="right">
            <template slot-scope="scope">
              <div class="top">
                <el-button type="primary" size="small" @click="track(scope.row)"
                  ><i class="el-icon-time"></i>订单详情</el-button
                >
              </div>
              <div class="box types">
                <!-- <el-button
                  class="btn"
                  type="primary"
                  size="mini"
                  :disabled="scope.row.status !== '1'"
                  >{{
                    scope.row.status !== "1" ? "审核已通过" : "审核"
                  }}</el-button
                > -->
                <el-button
                  class="btn"
                  type="primary"
                  size="mini"
                  v-if="scope.row.whetherFullPayment === 1"
                  @click="send(scope.row)"
                  >确认收款</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.currentPage"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!--订单详情-->
      <el-dialog
        :visible.sync="detail"
        class="detail"
        :title="title"
        width="55%"
      >
        <order-details
          v-bind:orderId="orderInfo.id"
          :key="timer"
        ></order-details>
      </el-dialog>

      <!-- 收款完成 -->
      <el-dialog title="确定收款" :visible.sync="sendOrder" width="30%">
        <p>当前订单是否全部支付成功？</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="sendOrder = false">取 消</el-button>
          <el-button type="primary" @click="sureSend">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "../../api/webAdmin/webAdmin";
import OrderDetails from "../OrderDetails/OrderDetails";
import picUrl from "../../api/picture";

export default {
  name: "orderManage",
  components: {
    OrderDetails,
  },
  data() {
    return {
      options2: [
        // 订单支付状态
        {
          value: "0",
          label: "全款",
        },
        {
          value: "1",
          label: "30%预付款",
        },
      ],
      whetherFullPayment: "1",
      sendOrder: false,
      startTime: "", // 开始时间
      endtime: "", //结束时间
      cpid: "", // 模糊查询
      pages: {
        pageSize: 5,
        currentPage: 1,
      },
      total: 0,
      tableData: [],
      orderType: "", // 订单生产状态筛选
      orderPayType: "", // 订单支付状态筛选
      options1: [
        // 订单支付状态
        {
          value: "3",
          label: "待支付",
        },
        {
          value: "5",
          label: "已支付",
        },
      ],
      orderId: "", // 订单id

      detail: false, // 订单详情弹窗打开
      title: "", //订单详情弹窗打开标题
      orderInfo: {},
      orderInfos: {},
      timer: "",

      picUrls: "",
      userId: "", // 当前管理员id
      userType: "",
    };
  },
  created() {
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (obj) {
      this.userId = obj.user_ID;
      this.userType = obj.userType;
    }

    this.picUrls = picUrl.picUrl;
    this.init();
  },
  methods: {
    // 判断生产状态
    typeFunc(v) {
      let text = "";
      if (v === "-1") {
        return (text = "待核价");
      } else if (v === "1") {
        return (text = "待审核");
      } else if (v === "2") {
        return (text = "待排产");
      } else if (v === "3") {
        return (text = "生产中");
      } else if (v === "4") {
        if (this.userType === 0) {
          return (text = "生产完成");
        } else {
          return (text = "待入库");
        }
      } else if (v === "5") {
        if (this.userType === 0) {
          return (text = "待发货");
        } else {
          return (text = "已入库");
        }
      } else if (v === "6") {
        if (this.userType === 0) {
          return (text = "已发货");
        } else {
          return (text = "待签收");
        }
      } else if (v === "7") {
        return (text = "订单取消");
      } else if (v === "8") {
        return (text = "订单完成(已评价)");
      } else if (v === "9") {
        return (text = "待印前制作");
      } else if (v === "10") {
        return (text = "待客确认");
      } else if (v === "11") {
        return (text = "已收货");
      } else {
        return text;
      }
    },
    // 刷新
    refresh() {
      this.startTime = "";
      this.endtime = "";
      this.cpid = "";
      this.orderType = "";
      this.orderPayType = "";
      this.whetherFullPayment = "1";
      this.init();
    },
    // 收款
    send(item) {
      this.orderId = item.id;
      this.sendOrder = true;
    },
    // 确认收款
    sureSend() {
      api
        .editFull(this.orderId)
        .then((res) => {
          // console.log("发货成功", res);
          if (res.success) {
            this.init();
            this.sendOrder = false;
            this.orderId = "";
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 查询
    query() {
      // if (
      //   this.startTime === "" &&
      //   this.endtime === "" &&
      //   this.cpid === "" &&
      //   this.orderType === "" &&
      //   this.orderPayType === ""
      // ) {
      //   this.$message.warning("请输入查询参数");
      //   return;
      // }
      this.init();
    },
    // 获取订单列表
    init() {
      api
        .queryOrder(
          this.startTime,
          this.endtime,
          this.cpid,
          this.pages.pageSize,
          this.pages.currentPage,
          this.orderType,
          this.orderPayType,
          this.whetherFullPayment
        )
        .then((res) => {
          // console.log("商城订单", res);
          if (res.success) {
            this.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message.error("获取订单失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.init();
    },
    // 订单详情
    track(item) {
      this.timer = new Date().getTime();
      console.log(item);
      this.orderInfo = item;
      this.title = "订单【" + this.orderInfo.id + "】详情";
      this.detail = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  .screen {
    margin-top: 10px;
    .colls {
      display: flex;
      line-height: 40px;
      margin: 15px 5px;
      span {
        margin-right: 5px;
      }
      .kuoz {
        margin: 0 10px;
      }
    }
    .coll {
      display: flex;
      justify-content: space-around;
      .btn {
        margin-left: 20px;
      }
    }
  }
  .card-form {
    display: flex;
    line-height: 40px;
    margin: 20px auto 0;
    margin-right: 20px;
    justify-content: flex-end;
    ::v-deep .el-input__icon {
      height: 0;
    }
    div {
      padding: 0 5px;
    }
  }
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .tab {
    padding: 10px;
  }
  .page {
    margin-top: 20px;
  }
  .tables {
    ::v-deep .el-button {
      margin-bottom: 3px;
    }
    ::v-deep .el-button + .el-button {
      margin-left: 0;
    }
    ::v-deep .el-table .cell {
      padding: 0;
    }
    ::v-deep .el-table .cell,
    ::v-deep .el-table--border td:first-child .cell {
      padding: 0;
    }
    ::v-deep .el-table_1_column_1 {
      padding: 0;
    }
    ::v-deep .el-table td {
      padding: 0;
    }
    .top {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #f8f8f8;
      color: #b4b4b4;
      span {
        color: #000000;
      }
    }
    .box {
      padding: 5px 10px;
      height: 102px;
      display: flex;
      justify-content: space-around;
      .img {
        margin: 4px 15px 0;
        width: 80px;
        height: 80px;
        img {
          display: inline-block;
          width: 80px;
          height: 100%;
        }
      }

      .info {
        width: 35%;
        .p1 {
          color: #409eff;
        }
        .p3 {
          span {
            color: #ff0000;
          }
          .type {
            margin-left: 5px;
          }
        }
      }
      .userInfo {
        .p3 {
          span {
            color: #ff0000;
          }
        }
        line-height: 25px;
        .span1 {
          color: #409eff;
        }
        .span2 {
          color: #ff0000;
        }
      }
    }
    .types {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        padding: 10px 0;
      }
      .btn {
        width: 50%;
      }
    }
  }
  .ipt {
    margin: 5px;
  }
  .bottom {
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }
  .upFile {
    text-align: center;

    .top {
      margin-bottom: 10px;

      .date {
        margin-top: 10px;

        .btn {
          margin-left: 10px;
        }
      }
    }

    .tableList {
      margin-bottom: 10px;

      .success {
        color: #058af2;
      }

      .error {
        color: #ff0000;
      }
    }

    .noTable {
      text-align: center;
      color: #66b1ff;
      margin: 15px;
    }

    .upBtn {
      margin-top: 15px;
    }
    /*操作_浏览按钮*/
    .browse {
      padding: 0;

      .liulan {
        color: white;
        text-decoration: none;
        height: 100%;
        padding: 7px 15px;
        display: block;
        text-align: center;
      }
    }
  }
}
</style>

<style lang="scss">
/*订单详情*/
.detail {
  .el-dialog {
    height: 75%;
    overflow: auto;

    .el-dialog__header {
      padding: 20px 20px 20px;
      // position: fixed;
      background: #fff;
      width: calc(55% - 40px);
    }

    // .el-dialog__body {
    //   margin-top: 64px;
    // }
  }
}
</style>
